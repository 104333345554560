// bootstrap code goes here
import React, { Component } from 'react';
import { MeteoPanelContext } from '../context/MeteoPanelContext';

import { decimals } from 'app/legacy/react/core/CoreUtils';
import { TableHeader } from 'semantic-ui-react';


// require('object-assign');
var Moment = require('moment');
Moment.locale('pl');

var loaderImage = require('app/images/loading.gif').default;

var LoaderComp = () => <div style={{ border: '1px solid black', height: 280 }}>
  <img style={{ position: 'relative', left: '50%', top: '50%', marginLeft: -10, marginTop: -10 }} src={loaderImage} />
</div>;


const numberStyle = {
  textAlign: 'center', padding: 2, fontSize: '12px', fontWeight: 'bold',
  //border: '1px solid black'
};

const headerStyle = {
  textAlign: 'center', padding: 5, fontSize: '18px', fontWeight: 'bold',
  //border: '1px solid #eee',
  borderRadius: 10, background: '#eee', borderColor: '#eee'
}

const unitStyle = {
  textAlign: 'center', padding: 10, fontSize: '28px', fontWeight: 'bold',
  //border: '1px solid black'
}

const textStyle = {
  textAlign: 'center', padding: 2, fontSize: '12px', fontWeight: 'bold',
  //border: '1px solid black',
  borderRadius: 10,
  background: '#eee', color: 'black'
}

// chwilowe, 
// 1minutowe
// 2minutowe 
// 10minutowe 

const ViewConfig = {

  headers: [
    '', 'WD', 'min', 'max', 'WS', 'min', 'max'
  ],

  data: [
    {
      title: 'Chwilowe', like: ''
    },
    {
      title: '1 minutowe', like: '1m'
    },
    {
      title: '2 minutowe', like: '2m'
    },
    {
      title: '10 minutowe', like: '10m'
    }
  ]
}


class WindStatsTable extends Component {

  state = {
  }

  render() {
    return (
      <MeteoPanelContext.Consumer key={"wind"}>
        {(context) => {
          var { windData } = context;

          if (windData == this.windData) {
            return (this.cachedRenderResult)
          }

          this.windData = windData;

          console.log('creating  table with wind data: ', context)

          return (
            windData && windData.wd ? (
              this.cachedRenderResult = 
              <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: 10 }}>
                <table key={"t"} cellSpacing={1} style={{ width: '100%', height: '100%' }}>
                  <thead key={"h"}>
                    <tr>
                      {
                        ViewConfig.headers.map((h, i) => {
                          return (
                            <td key={i} style={textStyle}>{h}</td>
                          )
                        })
                      }
                    </tr>
                  </thead>

                  <tbody key={"b"}>
                    {
                      ViewConfig.data.map((r, i) => {
                        return (
                          <tr key={i}>
                            {
                              ['', 'wd_v', 'wd_i', 'wd_x', 'ws_v', 'ws_i', 'ws_x'].map((c, k) => {
                                var key = c + r.like + '10s';

                                return (
                                  c == '' ? (
                                    <td key={k} style={numberStyle}>{r.title}</td>
                                  )
                                  : (
                                    <td key={k} style={numberStyle}>
                                      {
                                        r.like == '' ? (
                                          ['wd_v', 'ws_v'].indexOf(c) > -1 ? decimals(windData[c.split('_')[0]].val, 1) : '-'
                                        ) : (
                                          windData[key] ? decimals(windData[key].val, 1) : '-'
                                        )
                                      }
                                    </td>
                                  )
                                )
                              })
                            }
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>

              </div>
            ) : <LoaderComp />
          )
        }}
      </MeteoPanelContext.Consumer>
    )
  }
}


export default WindStatsTable;