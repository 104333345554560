
import api from '../data/FeyApi';


import {
    processResponse
} from '../data/ChartUtils';

// legacy utilities libraries
import {
    dateToUts, roundDate
} from '../../fey/data/FeyUtils';




// reused fetch data promise 
const FetchDataPromise = (parent, { url, uts1, uts2, vars, step, dataType, mem, processFunc }, context, info) => {
    return new Promise((resolve, reject) => {
        api.fetchData(url, {
            uts1, uts2,
            // in gql we use underscore to allow names started with numbers 
            //vars: vars.map((v) => v.substring(1)),
            vars: vars.map((v) => v.substring(1).replaceAll('_','.')),
            dataType: dataType,
            mem
        })
        .then((response) => {
            // use provide process response or just return response
            return resolve(processFunc ? processFunc(response, step).values : response)
        })
        .catch((response) => reject(response));
    })
};

const resolvers = {
    Query: {

        // return processed response from server 
        chartData(parent, queryArgs, context, info) {
            return FetchDataPromise(parent, {...queryArgs, processFunc: processResponse}, context, info);
        },

        // return raw response from the server 
        rawData(parent, queryArgs, context, info) {
            // NOTICE: problem with returned __typename ...

            console.log('fetching raw data: ', queryArgs)
            return FetchDataPromise(parent, {...queryArgs, processFunc: (response, step) => processResponse(response, step, 0)}, context, info)
        },

        // live data, replace uts2 with now
        liveData(parent, queryArgs, context, info) {
            var now = new Date();
            roundDate(now, '10s');
    
            var uts2 = dateToUts(now);
            var uts1 = uts2 - 60;

            return FetchDataPromise(parent, {...queryArgs, uts2, uts1, processFunc: processResponse}, context, info);
        },

    }
}

export default resolvers;