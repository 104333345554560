var jquery = require('jquery');

const Ajax = {

  jsonp: (url, params) => {
    this.req(url, Object.assign({jsonp: 'callback', dataType: 'jsonp'}, params))
  },
  
  req: (url, params) => {
    jquery.ajax(url, params)
  },

  promise: (url, params) => {
    return new Promise(function (resolve, reject) {
      
      params.success = (...args) => {
        resolve(...args);
      }

      params.error = (...args) => {
        console.log('promise error: ', args )
        reject(...args);
      }

      Ajax.req(url, params);
    })
  },

  promise_json: (url, params) => {
    return Ajax.promise(url, Object.assign({dataType: 'json'}, params))
  },

  promise_jsonp: (url, params) => {
    return Ajax.promise(url, Object.assign({dataType: 'jsonp'}, params))
  }
}

export default Ajax;
