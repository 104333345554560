// features:
//  - filtering
//  - sear
// import react and some other libs
// import {
//   lodash as _
// } from 'app/react/Boot.jsx';
var _ = require('lodash');

// it can be used as session
class DataStore {

  constructor (config) {

    var {
      data,
      opts,
      name,
      model,
      ...other
    } = config;

    // we can configure some options like rest for making http requests
    this.setData(data);;

    // options include idKey, and name
    this.opts = opts;
    this.model = model;

    // idMap
    this.idMap = {};
    this.idList = [];
    this.validIdMap = false;
  }

  setData(data) {
    this.data = data;
    this.validIdMap = false;

    if (this.model) {
      this.getRecordById(0);
    }
  }

  appendData(data) {
    // we simple concatenate two tables
    this.data = this.data.concat(data);

    // and update id map for new data
    this.updateIdMap(data, false)
  }

  getData() {
    return this.data;
  }

  getIds() {
    return this.idList;
  }

  updateIdMap(data, clean) {

    if (clean) {
      this.idMap = null; this.idMap = {};
      this.idList = null; this.idList = [];
    }

    var idKey = this.model.id;
    data.forEach((r, i) => {
      var id = r[idKey];

      if (this.idList.indexOf(id) == -1) {
        this.idList.push(id);
      }

      if (this.idMap[id]) {
        if (_.isArray(this.idMap[id])) {
          this.idMap[id].push(r);
        }  else {
          var ref = this.idMap[id];

          this.idMap[id] = null;
          this.idMap[id] = new Array();
          this.idMap[id].push(ref);
          this.idMap[id].push(r);
        }
      } else {
        this.idMap[id] = r;
      }
    })

    this.validIdMap = true;
  }

  getRecordById(id) {

    if (!this.validIdMap) {
      this.updateIdMap(this.data, true);
    }

    return this.idMap[id];
  }

  hasId(id) {
    return this.idMap.hasOwnProperty(id);
  }

  getRecordAtIndex(idx) {
    return this.data.length > idx ? this.data[idx] : null;
  }

  filterByRegExp(fieldName, re) {
    return this.data.filter((r, i) => {
      return re.test(r[fieldName]);
    })
  }

  filterBy(fieldName, match) {
    var re = new RegExp('.*' + match + '.*');
    return this.data.filter((r, i) => {
      return re.test(r[fieldName]);
    })
  }

  filterByFun(fun) {
    return this.data.filter(fun);
  }

  filter(arg1, arg2) {

    if (typeof arg1 == 'function') {
      return this.filterByFun(arg1);
    }

    if (typeof arg1 == 'string' && typeof arg2 == 'string') {
      return this.filterBy(arg1, arg2);
    }

    if (typeof arg1 == 'string' && typeof arg2 == 'string') {
      return this.filterBy(arg1, arg2);
    }

    if (typeof arg1 == 'string' && typeof arg2 == 'object') {
      return this.filterByRegExp(arg1, arg2);
    }

    throw new Error('No filter function matched arguments: ', typeof arg1, typeof arg2);
  }

  forEach(fun) {
    return this.data.forEach(fun);
  }

  map(fun) {
    return this.data.map(fun);
  }

  some(fun) {
    return this.data.some(fun);
  }
}

//
// var ds = new DataStore({
//   data: [1, 2, 3, 4, 5, 6, 7]
// })
//
//
// console.log('data store: ', ds.data.filter((c) => c == 4))

export default DataStore;
