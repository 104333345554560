import { exportDefaultSpecifier } from "@babel/types";

function buildSerieSpec(code, aggType) {
    var parts = code.split(':');

    if (parts.length === 2) return code;

    return aggType === "10s" ? parts[0] : parts[0] + ':' + aggType;
}

function buildQueryVars(vars, step, config) {
    return vars.map((v) => {

        // get rid of underscore 
        var name = v.substring(1);

        // default result type is arithmetic 
        var resultType = 'A';

        if (config && config[name] && config[name].average_type) {
            // first test for configuration 
            resultType = config[name].average_type[0].toUpperCase()
        } else {
            if (/WD|WV|WS/.test(name)) {
                resultType = 'V';
            } else if (/RAIN/.test(name)) {
                resultType = 'S';
            }
        }

        return step === "10s" ? v : "\"" + v + ":" + resultType + step + "\"";
    })
}

function buildQueryVarsWithTypes(vars, types, step) {
    var result = [];

    vars.forEach((v) => {
        // get rid of underscore 
        var name = v.substring(1);
        
        types.forEach((t) => {
            var resultType = t;

            result.push(
                step === "10s" ? v : "\"" + v + ":" + resultType + step + "\""
            );

        })
    })

    return result;
}


function dateToUts(date) {
    return Math.floor(date.getTime() / 1000);
}

function roundDate(date, type = '10s') {
    switch (type) {
        case '1m':
            date.setSeconds(0);
            break;
        case '10m':
            date.setMinutes(date.getMinutes() - (date.getMinutes() % 10));
            date.setSeconds(0);
            break;
        case '1h':
            date.setMinutes(0);
            date.setSeconds(0);
            break;
        case '24h':
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            break;
        default:
        case '10s':
            date.setSeconds(date.getSeconds() - (date.getSeconds() % 10));
            break;
    }
}

function nextDate(date, type = '10s') {
    switch (type) {
        case '1m':
            date.setSeconds(0);
            break;
        case '10m':
            date.setMinutes(date.getMinutes() + (10 - date.getMinutes() % 10));
            date.setSeconds(0);
            break;
        case '1h':
            date.setHours(date.getHours() + 1);
            date.setMinutes(0);
            date.setSeconds(0);
            break;
        case '24h':
            date.setDate(date.getDate() + 1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            break;

        default:
        case '10s':
            date.setSeconds(date.getSeconds() + (10 - date.getSeconds() % 10));
            break;

    }
}


export {
    buildSerieSpec, dateToUts, roundDate, nextDate, buildQueryVars, buildQueryVarsWithTypes
}