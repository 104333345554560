import { gql } from 'apollo-boost';


export const typeDefs = gql`
    type measurement {
        id: Int!
        code: String!
        name: String
        physical_device_id: Int!
        agg_unit: String
        decimals: Int
        format: String
        compound_type: String
        unit_id: String
    }

    type physical_device {
        id: Int!
        name: String!
        device_type_id: Int
        station_id: Int
        protocol: String
        address: String
        conn_args: String
        active: Int
        measurements: [measurement]
    }

    type location {
        id: Int!
        name: String 
        device_type_id: Int
        station_id: Int
        protocol: String
        address: String
        conn_args: String
        active: Int
        measurement: String
    }
`
