
function findClosestAngle(oldAngle, newAngle) {
    var a = oldAngle, b = newAngle;
    var d1, d2;

    if (a < b) {
        a += 360;
    } else if (a > b) {
        b += 360;
    }

    d1 = newAngle - oldAngle;
    d2 = b - a;

    //console.log('better angle', oldAngle, newAngle, d1, d2)


    if (Math.abs(d2) > Math.abs(d1)) {
        return d1
    }

    return d2;
}

const WindDirections = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW', 'N'];



export {
    findClosestAngle, WindDirections
}