
var binarySearch = require('binary-search');

var _ = require('lodash');
var d3 = require('d3');

class BaseSerie {

  constructor (baseConfig, userConfig) {

    // merges base and user configs into current object
    Object.assign(this, baseConfig, {

      // serie id
      id: 'id', // we could generate some id here

      // data associated with series, (array of objects)
      data: null,

      // serie custom options
      opts: {
        // ... some parameters used in rendering
      }

    }, userConfig)
  }

  // default time accessor
  timeAccessor (d) {
    return d.t;
  }

  // default value accessor
  valueAccessor (d) {
    return d.v;
  }

  // returns index of nearest object associated with given position on x axis
  _x2i(x) {
    var c = this.timeline.d3scale.invert(x);
    var s = Math.floor(c.getTime() / 1000.0);
    var so = {t: s}

    var q = binarySearch(this.data, so, (a, b) => this.timeAccessor(a) - this.timeAccessor(b))
    var z = q;

    if (q == -1) {
      // outside of the array on the left
      q = 0;
    }

    if (q < -1) {
      q += 2;
      q *= -1;

      var dl = (s - this.timeAccessor(this.data[q]));
      var dr = (this.data.length > q + 1) ? (this.timeAccessor(this.data[q+1]) - s) : dl;

      // normal behaviour is to get the left value
      // but we can test if the right value is closer
      if (dr < dl && this.data.length > q + 1) {
        // get the right value
        q = q + 1;
      }

    }

    return q;
  }

  // returns time of given position on x axis
  _x2t(x) {
    return this.timeline.d3scale.invert(x);
  }

  // returns position on x axis of given time
  _t2x(t) {
    return this.timeline.d3scale(t);
  }

  // returns index of nearest object associated with given time
  _t2i(t) {
    return this._x2i(this._t2x(t));
  }

  // returns position on y axis of given value
  _v2y(v) {
    return this.scale.d3scale(v);
  }

  // returns value of given position on y axis
  _y2v(y) {
    return this.scale.d3scale.invert(y);
  }

  // returns x of i
  _i2x(i) {
    // we assume that distance between to consequitive points is larger than 1 pixel 
    let v1 = this.timeAccessor(this.data[0]), v2 = this.timeAccessor(this.data[1]);
    let x1 = this._t2x(v1), x2= this._t2x(v2);
    let df = x2 - x1;
    let dt = v2 - v1;

    return this._t2x(v1 + dt * i);
  }

  _drawLine(svg, size) {

    // create line generator
    this.line = d3.svg.line()
    .defined((d) => {
      //console.log('checking defined: ', this.valueAccessor(d))
      return !isNaN(this.valueAccessor(d));
    })
    .x(
      (d) => Math.round(this.timeline.d3scale(this.timeAccessor(d))) + 0.5
    ).y(
      (d) => Math.round(this.scale.d3scale(this.valueAccessor(d))) + 0.5
    )
    //.interpolate('cardinal');
    // .interpolate('step-before')
    // .interpolate('basis')
    .interpolate('monotone')
    // .interpolate('linear')
    // // cardinal, monotone

    // console.log('my value scale: ', this.scale.extent)
    this.lineData = this.line(this.data);
    // console.log('data for chart: ', this.data, this.lineData)

    // this for react
    svg.append('g').attr({
      fill: 'none'
    }).append('path').attr({
      'd' : this.lineData,
    })
    //.attr('data-legend', 'timestamp')
    //.style('filter', 'url(#dropshadow)')
    .attr(Object.assign({}, this.style));
  }

  _drawHorizontalBars(svg, size, idx, max) {

    // create line generator
    this.line = d3.svg.line()
    .defined((d) => {
      //console.log('checking defined: ', this.valueAccessor(d))
      return !isNaN(this.valueAccessor(d));
    })
    .x(
      (d) => Math.round(this.timeline.d3scale(this.timeAccessor(d))) + 0.5
    ).y(
      (d) => Math.round(this.scale.d3scale(this.valueAccessor(d))) + 0.5
    )
    //.interpolate('cardinal');
    // .interpolate('step-before')
    // .interpolate('basis')
  //  .interpolate('monotone')
    // .interpolate('linear')
    // // cardinal, monotone

    // console.log('my value scale: ', this.scale.extent)
    this.lineData = this.line(this.data);

    // console.log('data for chart: ', this.data, this.lineData)
    var barWidth = (size.height / max) - 3;
    var barPos = (size.height / max);

    // this for react
    //.attr('data-legend', 'timestamp')
    //.style('filter', 'url(#dropshadow)')
    // bar chart
    var timeline = this.timeline;
    var scale = this.scale;
    var valueAccessor = this.valueAccessor;
    var timeAccessor = this.timeAccessor;

    var data = this.data;

    //  console.log('what is my size: ', size);
    svg.append('g').attr({
      fill: 'none'
    }).attr(Object.assign({}, this.style))
    .selectAll(".bar")
    .data([this.data[0]])
    .enter().append("rect")
    .attr("class", "bar")
    .attr({fill: this.style.stroke, strokeWidth: 0})
    .attr("x", function(d) {
      return size.x;
    })
    .attr("y", function(d) {

  //    console.log('valueAccessor: ', Math.round(scale.d3scale(scale.extent[0])), idx * barPos)
      return Math.round(scale.d3scale(scale.extent[0])) + 0.5 - (idx * barPos) - barWidth - 1;
  //    return (idx * barPos);
    })
    .attr("width", function(d) {
      var k = Math.round(timeline.d3scale(timeAccessor(data[1]))) + 0.5;

    //  console.log('percentages: ', valueAccessor(d), idx, max, k, size.x, size.width, timeline.extent, timeline.d3scale(timeAccessor(data[1])));
      return (valueAccessor(d) / 100) * (k - size.x);
    })
    .attr("height", function(d) { return barWidth; })

    svg.append('g').attr({
      fill: 'none'
    })
    .selectAll(".text")
    .data([this.data[0]])
    .enter().append("text")
    .attr("class", "text")
    .attr('text-anchor', 'end')
    //.attr('dy', '.3em')
    .attr({fill: 'black', strokeWidth: 0})
    .text(this.legendName)
    .attr("x", function(d) {
      return size.x - 10;
    })
    .attr("y", function(d) {
      return  Math.round(scale.d3scale(scale.extent[0])) + 0.5 - (idx * barPos) - barWidth / 2 + 5;
    })
    .attr("width", function(d) {
      return size.x;
    })
    .attr("height", function(d) { return barWidth; })
  }

  _drawBars(svg, size) {

    // create line generator
    this.line = d3.svg.line()
    .defined((d) => {
      //console.log('checking defined: ', this.valueAccessor(d))
      return !isNaN(this.valueAccessor(d));
    })
    .x(
      (d) => Math.round(this.timeline.d3scale(this.timeAccessor(d))) + 0.5
    ).y(
      (d) => Math.round(this.scale.d3scale(this.valueAccessor(d))) + 0.5
    )
    //.interpolate('cardinal');
    // .interpolate('step-before')
    // .interpolate('basis')
    .interpolate('monotone')
    // .interpolate('linear')
    // // cardinal, monotone

    // console.log('my value scale: ', this.scale.extent)
    this.lineData = this.line(this.data);
    // console.log('data for chart: ', this.data, this.lineData)

    var barWidth = (size.width / this.data.length) - 1;

    // this for react
    //.attr('data-legend', 'timestamp')
    //.style('filter', 'url(#dropshadow)')
    // bar chart
    var timeline = this.timeline;
    var scale = this.scale;
    var valueAccessor = this.valueAccessor;
    var timeAccessor = this.timeAccessor;

    //  console.log('what is my size: ', size);
    svg.append('g').attr({
      fill: 'none'
    }).attr(Object.assign({}, this.style))
    .selectAll(".bar")
    .data(this.data)
    .enter().append("rect")
    .attr("class", "bar")
    .attr({fill: this.style.stroke, strokeWidth: 0})
    .attr("x", function(d) {
      var k = Math.round(timeline.d3scale(timeAccessor(d))) + 0.5 - 0.5 * barWidth
      // console.log('my k ', k)

      if (k >= size.x + size.width - barWidth) {
        return k;
      } else if(k <= size.x + 1) {
        return k + 0.5 * barWidth;
      } else {
        return k;
      }
    })
    .attr("y", function(d) { return Math.round(scale.d3scale(valueAccessor(d))) + 0.5 })
    .attr("width", function(d) {
      var k = Math.round(timeline.d3scale(timeAccessor(d))) + 0.5 - 0.5 * barWidth
      if (k >= size.x + size.width - barWidth - 1 || k <= size.x + 1) {
        return 0.5 * barWidth;
      } else {
        return barWidth;
      }
    })
    .attr("height", function(d) { return size.y - 2 + size.height - Math.round(scale.d3scale(valueAccessor(d))) + 0.5; })
  }

  _drawArea(svg, size) {

    // create line generator
    this.line = d3.svg.area()
    .defined((d) => {
      //console.log('checking defined: ', this.valueAccessor(d))
      return !isNaN(this.valueAccessor(d));
    })
    .y0(size.y + size.height)
    .x(
      (d) => Math.round(this.timeline.d3scale(this.timeAccessor(d))) + 0.5
    ).y1(
      (d) => Math.round(this.scale.d3scale(this.valueAccessor(d))) + 0.5
    )
    //.interpolate('cardinal');
    //.interpolate('step-before')
    // .interpolate('basis')
    .interpolate('monotone')
    // .interpolate('linear')
    // // cardinal, monotone

    // console.log('my value scale: ', this.scale.extent)
    this.lineData = this.line(this.data);
    // console.log('data for chart: ', this.data, this.lineData)

    // this for react
    svg.append('g').attr({
      fill: 'none'
    }).append('path').attr({
      'd' : this.lineData,
      //class: 'area',
      fill: this.style.stroke,
      strokeWidth: 0
    })
    //.attr('data-legend', 'timestamp')

    // svg.select('.area').attr({
    //   fill: this.style.stroke,
    //   strokeWidth: 0
    // })
    //
    //.style('filter', 'url(#dropshadow)')
    .attr(Object.assign({}, this.style));
  }

  
  _drawArrows(svg, size) {
    // console.log('data for chart: ', this.data, this.lineData)
    var barWidth = ( (0.7 * size.width) / this.data.length);
    var oBarWidth = barWidth;

    barWidth = barWidth > 10 ? (barWidth > 15 ? 15: barWidth): 10;
    // this for react
    //.attr('data-legend', 'timestamp')
    //.style('filter', 'url(#dropshadow)')
    // bar chart
    var timeline = this.timeline;
    var scale = this.scale;
    var valueAccessor = this.valueAccessor;
    var timeAccessor = this.timeAccessor;

    //  console.log('what is my size: ', size);
    var Me = this;
    svg.append('g').attr({
      fill: 'none'
    }).attr(Object.assign({}, this.style))
    .selectAll(".arrow")
    .data(this.data)
    .enter().append("path")
    .attr("class", "arrow")
    .attr({fill: this.style.stroke, strokeWidth: 0.01})
    .attr("d", function(d, i) {
      var x = Math.round(timeline.d3scale(timeAccessor(d))) + 0.5;
      var y = barWidth;
      var v = (valueAccessor(d) + 180) % 360;
      var halfBarWidth = 0.4 * barWidth;
      var arrowWidth = barWidth * 0.6;
      var points = new Array();
      var mcos = Math.cos(v * Math.PI / 180);
      var msin = Math.sin(v * Math.PI / 180);
      var lastPoint;
      var lineWidth = 4;
      var capSize = 5;

      if ((i != Me.data.length - 1) && (oBarWidth < 10 && i % Math.floor(13 / oBarWidth))) {
        return "";
      }

      // start point
      points.push([
        x - lineWidth / 2, barWidth
      ]);

      // end point (arrow 1)
      points.push([
        0, -(barWidth - arrowWidth)
      ]);

      // arrow 2
      points.push([
        -capSize / 2, 0
      ])

      // end point (arrow 1)
      points.push([
        capSize / 2 + lineWidth / 2, -arrowWidth
      ]);

      // // arrow 2
      points.push([
        lineWidth / 2 + capSize /2  , arrowWidth
      ])

      // and we need to close the arrow
      points.push([
        -capSize / 2, 0
      ]);

      points.push([
        0, barWidth - arrowWidth
      ]);

      points.push([
        -lineWidth, 0
      ])

      return points.map((r, i) => {
        return ((i == 0) ? "M " : "l ") + r.join(" ") + (i == points.length - 1 ? " z" : "");
      })
    }).
    attr('transform', function (d) {
      var x = Math.round(timeline.d3scale(timeAccessor(d))) + 0.5;
      var y = barWidth;
      var halfBarWidth = 0.5 * barWidth;
      var v = (valueAccessor(d) + 180) % 360;

      var cx = x;
      var cy = halfBarWidth;

      return "rotate(" + v + " " + cx + " "+ cy + ")";
    });
  }



  _draw(svg, size, idx, max) {
//    this._drawArea(svg, size);

    switch (this.drawType) {
      case "hbars":
        this._drawHorizontalBars(svg, size, idx, max);
      break;
      case "bars":
        this._drawBars(svg, size);
      break;
      case "area":
        this._drawArea(svg, size);
      break;
      case "arrows":
        this._drawArrows(svg, size);
      break;
      case "line":
      default:
        this._drawLine(svg, size);
      break;
    }


  //  this._drawBars(svg, size);
  //  this._drawLine(svg, size);
  }

  _getScaleExtent() {
    return d3.extent(this.data, this.valueAccessor);
  }

  // this function will update scales base on data or configuration
  _updateScales(size) {

    //
    if (this.data.length == 0) {
      return 0;
    }

    // we should be able to reduce number of computations with some state markers
    if (this.timeline.type == "dynamic") {
      // this.timeline.extent  = d3.extent(this.data, this.timeAccessor);
      this.timeline.extent  = [ this.timeAccessor(this.data[0]), this.timeAccessor(this.data[this.data.length - 1]) ];

    } else {
      // static
      this.timeline.extent = this.timeline.range;
    }

    // create d3scale for xaxis
    this.timeline.d3scale = d3.time
    .scale()
    .domain(this.timeline.extent)
    .range([size.x, size.x + size.width])

    if (this.timeline.nice) {
      this.timeline.d3scale.nice();
    }

    if (this.scale.type == "dynamic") {
      this.scale.extent = d3.extent(this.data, this.valueAccessor);
    } else {
      // static
      this.scale.extent = this.scale.range;
    }

    if (this.scale.overrideRange) {
      this.scale.extent = this.scale.overrideRange;
    }

    // create d3scale for yaxis
    this.scale.d3scale = d3.scale
    .linear()
    .domain(this.scale.extent)
    .range([size.y + size.height, size.y])
    .nice();

    // this.scale.d3axis = d3.svg.axis().scale(this.scale.d3scale).orient(orient).tickSize(tickSize, 1, 0);
  }

}

class LineSerie extends BaseSerie {

  constructor (userConfig) {
    super({

      // serie name
      name: 'serie',

      // serie id
      id: 'id',

      // serie type
      type: 'line',

      // yscale for values
      scale: {
        type: 'dynamic',
        visible: false
        //        range: [100, 200]
      },

      // scale for timeline
      timeline: {
        type: 'dynamic',
        // range: [100, 200] ...
      },

      // serie abstract style
      style: {
        stroke: 'blue',
        strokeWidth: 1
      },

      // data associated with series, (array of objects)
      data: [],

      // serie custom options
      opts: {
        // ... some parameters used in rendering
      }
    }, userConfig)
  }

}

class ChartSeries {

  constructor () {
    // initialize object
  }

  createLineSerie(config) {
    // console.log('creating line serie with config: ', config)
    return Object.assign(new LineSerie(), config);
  }
}

//
// dynamicAxes: true, // is axes count dynamic ?
//
// // axes order
// axesOrder: [L, R, L, R],
// preciseAxesOrder: {
//   1: [L], 2: [L, R], 3: [L, R, L], 4: [L, R, L, R]
// },
//
// // this is future parameter for displaying multiple timelines (its not easy to do that)
// dynamicTimelines: false,
//
// // serie name
// name: 'serie',
//
// // serie id
// id: 'id',
//
// // serie type
// type: 'line',
//
// scale: {
//   type: 'dynamic',
//   visible: false
//   //        range: [100, 200]
// },
//
// // serie abstract style
// style: {
//   stroke: 'blue',
//   strokeWidth: 1
// },
//
// // data associated with series, (array of objects)
// data: [],
//
// // serie custom options
// opts: {
//   // ... some parameters used in rendering
//
// }
// }, config)

const ChartSeriesInst_ = new ChartSeries();

export default ChartSeriesInst_;
