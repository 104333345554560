
import React, { Component } from 'react';
import { MeteoPanelContext } from '../context/MeteoPanelContext';
import { Query } from 'react-apollo';

// legacy utilities libraries
import {
    buildSerieSpec, dateToUts, roundDate, nextDate, buildQueryVars
} from '../../fey/data/FeyUtils';

// import queries
import createLiveDataQuery from '../query/client/LiveData';
import { DatesRangeInput } from 'semantic-ui-calendar-react';


// Moment js
var Moment = require('moment');
Moment.locale('pl');


class LiveDataChecker extends Component {

    constructor () {
        super();
        
        this.now  = 0;

    }

    generateView(ctx, { loading, error, data }) {
        console.log('polling server, gettin result: ', ctx.now, data)
        if (error) return `Error! ${error.message}`;

        if (!loading && data) {
            var properValue = false;
            for (var idx = data.liveData.length - 1; idx >= 0; idx--) {
                var lastValue = data.liveData[idx];
         
                properValue = false;

                // check for proper value 
                for (var p in lastValue) {
                    if (p !== 't' && p !== '__typename') {
                        if (lastValue[p] !== '') {
                            // we assume that there is a value for this param 
                            properValue = true;
                        }
                    }
                }

                if (properValue) {
                    // report new value 
                    break;
                }
            }

            if (properValue) {
                
                var now = new Date(lastValue.t * 1000);
                var now1h = new Date(now);
                var uts = lastValue.t;

                roundDate(now, '10m');
                roundDate(now1h, '1h')

                var uts10m = dateToUts(now);
                var uts1h = dateToUts(now1h)
                
                if (ctx.now !== uts) {

                    console.log('setting new now value: ', ctx.now, ctx.now10m, uts, uts10m);
                    
                    // set context immedietly after render function finishes
                    //setTimeout(() => ctx.setState({ now: uts, now10m: uts10m, ui: {historyMode: {now1h: uts1h} }}), 1);
                    if (typeof this.token == "undefined" || this.token !== ctx.source) {
                        this.token = ctx.updateState("live", { 
                            now: uts, now10m: uts10m, ui: {
                                historyMode: {
                                    now1h: uts1h
                                } 
                            }
                        });
                    }

                }
            }
        }

        return null;
    }

    render() {

        console.log('rerendering live data checker')

        var {
            dataUrl, columns
        } = this.props;

        var step = '10s';
        return (
            <MeteoPanelContext.Consumer key={"checker"}>
                {(context) => {
                    let viewMode = context.ui.viewMode;
                    let pollInterval = viewMode == "default" ? 10 * 1000 : 0;

                   console.log('2 rerendering live data checker: ', context.measurement.length, viewMode, pollInterval)
                    return (
                        context.measurement.length ?
                            <Query fetchPolicy={"network-only"} pollInterval={ pollInterval }  query={
                                createLiveDataQuery({
                                    mem: 1,
                                    dataType: 'samples',
                                    dataUrl,
                                    step,
                                    columns,
                                    vars: buildQueryVars(columns, step, context.measurement), // server query vars
                                    context // pass context to query function 
                                })
                            }>{this.generateView.bind(this, context)}</Query> : null
                    )
                }}
            </MeteoPanelContext.Consumer>
        )
    }
}

export default LiveDataChecker;



