
import { gql } from 'apollo-boost';


function createLiveDataQuery({ dataUrl, dataType = "averages", vars, columns, step, mem = 0 }) {

    var mvars = vars.map((v) => v.replaceAll('.', '_'));
    var mcols = columns.map((v) => v.replaceAll('.', '_'));

    console.log('create live data query: ', vars, mvars)
    
    return gql`
    {
        liveData(url: "${dataUrl}", vars: [${mvars}], columns: [${mcols}], step: "${step}", dataType: "${dataType}", mem: ${mem}) @client {
            t ${mcols.join(" ")}
        } 
    }
    `;
}

export default createLiveDataQuery;