
import { gql } from 'apollo-boost';


function createRawDataQuery({ dataUrl, dataType = "averages", uts1, uts2, vars, columns, step, mem = 0 }) {
    
    return gql`
    {
        rawData(url: "${dataUrl}", uts1: ${uts1}, uts2: ${uts2}, vars: [${vars}], columns: [${columns}], step: "${step}", dataType: "${dataType}", mem: ${mem}) @client {
            t ${columns.join(" ")}
        } 
    }
    `;
}

export default createRawDataQuery;