

const MAX_VALUE = 9999999999999;


/* 

0-3 ou/m3 b.dobre - zielony
3-5 ou/m3 dobre - zielono-żołty (seledynowy)
5-10 ou/m3 umiarkowane (żółty)
10-30 ou/m3 dostateczne (pomarańczowy)
30-60 ou/m3 złe (czerwony)
powyżej 60 ou/m3 b. złe (bordowy)
*/

const helpItems = [
  {
    title: "BARDZO DOBRE",
    color: "#62B100",
    desc: "Jakość powietrza jest bardzo dobra. Warunki bardzo sprzyjające do wszelkich aktywności na wolnym powietrzu bez ograniczeń.",
    range: {
      pm10: [0, 20],
      pm25: [0, 13],
      o3:   [0, 70],
      no2:  [0, 40],
      so2:  [0, 50],
      benzen: [0, 6],
      co: [0, 3],
      odo: [0,3]
    }
  },
  {
    title: "DOBRE",
    color: "#B3DD00",
    desc: "Jakość powietrza jest zadowalająca. Można przebywać na wolnym powietrzu i wykonywać dowolną aktywność bez ograniczeń.",
    range: {
      pm10: [20.1, 50],
      pm25: [13.1, 35],
      o3:   [70.1, 120],
      no2:  [40.1, 100],
      so2:  [50.1, 100],
      benzen: [6.1, 11],
      co:   [3.1, 7],
      odo: [3.1, 5]
    }
  },
  {
    title: "UMIARKOWANE",
    color: "#FBD900",
    //desc: "Jakość powietrza jest akceptowalna. Warunki umiarkowane do aktywności na wolnym powietrzu.",
    desc: "Zanieczyszczenie powietrza może stanowić zagrożenie dla zdrowia, w szczególności dla osób chorych, osób starszych, kobiet w ciąży oraz małych dzieci.",
    range: {
      pm10: [50.1, 80],
      pm25: [35.1, 55],
      o3:   [120.1, 150],
      no2:  [100.1, 150],
      so2:  [100.1, 200],
      benzen: [11.1, 16],
      co:   [7.1, 11],
      odo: [5.1, 10]
    }
  },
  {
    title: "DOSTATECZNE",
    color: "#DE8000",
    //desc: "Jakość powietrza jest dostateczna. Należy rozważyć ograniczenie (skrócenie lub rozłożenie w czasie) aktywności na wolnym powietrzu.",
    desc: " Zanieczyszczenie powietrza stanowi zagrożenie dla zdrowia. Należy ograniczyć aktywności na wolnym powietrzu, szczególnie jeśli ta aktywność wymaga długotrwałego lub wzmożonego wysiłku fizycznego.",
    range: {
      pm10: [80.1, 110],
      pm25: [55.1, 75],
      o3:   [150.1,180],
      no2:  [150.1,200],
      so2:  [200.1,350],
      benzen: [16.1, 21],
      co:   [11.1, 15],
      odo: [10.1, 30]
    }
  },
  {
    title: "ZŁE",
    color: "#DB0000",
    desc: "Jakość powietrza jest zła. Ograniczyć do minimum wszelką aktywność fizyczną na wolnym powietrzu.",
    range: {
      pm10: [110.1, 150],
      pm25: [75.1, 110],
      o3:   [180.1, 240],
      no2:  [200.1, 400],
      so2:  [350.1, 500],
      benzen: [21.1, 51],
      co:   [15.1, 21] ,
      odo: [30.1, 60]
    }
  },
  {
    title: "BARDZO ZŁE",
    color: "#920000",
    desc: "Jakość powietrza jest bardzo zła i ma negatywny wpływ na zdrowie, ograniczyć przebywanie na wolnym powietrzu do niezbędnego minimum.",
    range: {
      pm10: [150.1, MAX_VALUE],
      pm25: [110.1, MAX_VALUE],
      o3: [240.1, MAX_VALUE],
      no2: [400.1, MAX_VALUE],
      so2: [500.1, MAX_VALUE],
      benzen: [51.1, MAX_VALUE],
      co: [21.1, MAX_VALUE],
      odo: [60.1, MAX_VALUE]
    }
  },
  {
    title: "BRAK DANYCH",
    color: "#BFBFBF",
    desc: "Indeks jakości powietrza nie jest wyznaczony z powodu braku pomiaru zanieczyszczenia dominującego w województwie.",
    range: {
      pm10: [-MAX_VALUE, MAX_VALUE],
      pm25: [-MAX_VALUE, MAX_VALUE],
      o3: [-MAX_VALUE, MAX_VALUE],
      no2: [-MAX_VALUE, MAX_VALUE],
      so2: [-MAX_VALUE, MAX_VALUE],
      benzen: [-MAX_VALUE, MAX_VALUE],
      co: [-MAX_VALUE, MAX_VALUE],
      odo: [-MAX_VALUE, MAX_VALUE]
    }
  },


]

export {
  helpItems
};
