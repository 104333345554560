
import {WindDirections} from './Utils'


var d3 = require('d3');

function drawDisk({ svg, size }) {
    var halfWidth = size.width / 2;
    var halfHeight = size.height / 2;

    var level = 0;
    var thickness = 0;
    var color = 'black';
    var startAngle = 8;
    var endAngle = 82;
    var arc;
    //    var x1, y1, x2, y2;
    var radAngle;
    var radius = halfWidth - 10;


    for (var i = 0; i < 4; i++) {

        arc = d3.svg.arc()
            .innerRadius(halfWidth - 19)
            .outerRadius(halfWidth - 20)
            .startAngle((i * 90 + startAngle) * Math.PI / 180)
            .endAngle((i * 90 + endAngle) * Math.PI / 180)

        svg.append('g')
            .attr('transform', 'translate(' + halfWidth + ', ' + halfHeight + ')')
            .append('path')
            .style('fill', color)
            .attr('d', arc)
    }

    for (var i = 0, angle = 0; angle = i * 22.5, angle < 360; i++) {
        radAngle = (angle - 90) * Math.PI / 180;

        var k = angle % 180 == 0 ? 40 : 70;

        var x1 = Math.cos(radAngle) * (radius - 10),
            y1 = Math.sin(radAngle) * (radius - 10),
            x2 = Math.cos(radAngle) * (k),
            y2 = Math.sin(radAngle) * (k);


        //console.log('windrose: ', i, angle, angle % 90)

        if (angle % 90 == 0) {

            // lines 
            svg.append('g')
                .append('svg:path')
                .attr('stroke', '#ff6383')
                .attr('stroke-width', 1)
                .attr('stroke-linecap', 'round')
                .attr('d', 'M' + x1 + ',' + y1 + 'L' + x2 + ',' + y2)
                .attr('transform', 'translate(' + halfWidth + ', ' + halfHeight + ')')

            x1 = Math.cos(radAngle) * (radius - -0);
            y1 = Math.sin(radAngle) * (radius - -0);

            svg.append('g')
                .append('svg:text')
                .attr('x', x1 - 5)
                .attr('y', y1 + 5)
                .attr('stroke', '#ff6383')
                .text(WindDirections[i])
                .attr('transform', 'translate(' + halfWidth + ', ' + halfHeight + ')')
        } else if (angle % 45 == 0) {

            x1 = Math.cos(radAngle) * (radius - 15);
            y1 = Math.sin(radAngle) * (radius - 15);
            x2 = Math.cos(radAngle) * (50);
            y2 = Math.sin(radAngle) * (50);

            // lines 
            svg.append('g')
                .append('svg:path')
                .attr('stroke', '#ccc')
                .attr('stroke-width', 1)
                .attr('stroke-linecap', 'round')
                .attr('d', 'M' + x1 + ',' + y1 + 'L' + x2 + ',' + y2)
                .attr('transform', 'translate(' + halfWidth + ', ' + halfHeight + ')')

            x1 = Math.cos(radAngle) * (radius - -0);
            y1 = Math.sin(radAngle) * (radius - -0);

            svg.append('g')
                .append('svg:text')
                .attr('x', x1 - 10)
                .attr('y', y1 + (angle > 90 && angle < 270 ? 10 : 0))
                .attr('stroke', '#999')
                .text(WindDirections[i])
                .attr('transform', 'translate(' + halfWidth + ', ' + halfHeight + ')')
        } else {
            x1 = Math.cos(radAngle) * (radius - 30);
            y1 = Math.sin(radAngle) * (radius - 30);
            x2 = Math.cos(radAngle) * (50);
            y2 = Math.sin(radAngle) * (50);

            //lines 
            svg.append('g')
                .append('svg:path')
                .attr('stroke', '#eee')
                .attr('stroke-width', 1)
                .attr('stroke-linecap', 'round')
                .attr('d', 'M' + x1 + ',' + y1 + 'L' + x2 + ',' + y2)
                .attr('transform', 'translate(' + halfWidth + ', ' + halfHeight + ')')

            x1 = Math.cos(radAngle) * (radius - 30);
            y1 = Math.sin(radAngle) * (radius - 30);

            svg.append('g')
                .append('svg:text')
                .attr('x', x1 - (angle > 180 ? 15 : 5))
                .attr('y', y1 + (angle > 90 && angle < 270 ? 10 : 0))
                .attr('stroke', '#aa')
                .attr('fontSize', 10)
                .text(WindDirections[i])
                .attr('transform', 'translate(' + halfWidth + ', ' + halfHeight + ')')
        }
    }
}


export {
    drawDisk
}