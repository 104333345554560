
import { gql } from 'apollo-boost';


function createWindDataQuery({ dataUrl, dataType = "averages", uts1, uts2, now, vars, vars2, vars3, columns, columns2, columns3, step, mem = 0 }) {

    return gql`
    {
        rawData(url: "${dataUrl}", uts1: ${uts1}, uts2: ${uts2}, vars: [${vars}], columns: [${columns}], step: "${step}", dataType: "org_averages", mem: ${mem}) @client {
            t ${columns.join(" ")}
        } 

        additionalData :
        rawData(url: "${dataUrl}", uts1: ${now-60}, uts2: ${now}, vars: [${vars2}], columns: [${columns2}], step: "10s", dataType: "samples", mem: 1) @client {
            t ${columns2.join(" ")}
        } 

        additionalAveragesData :
        rawData(url: "${dataUrl}", uts1: ${now-60}, uts2: ${now}, vars: [${vars3}], columns: [${columns3}], step: "10s", dataType: "org_averages", mem: 1) @client {
            t ${columns3.join(" ")}
        } 
    }
    `;
}

export default createWindDataQuery;