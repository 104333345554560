import {findClosestAngle} from './Utils'


var d3 = require('d3');


function createAnimatedRing() {
    var last = {
        //size: null, angle: null, length: null
    }

    return function ({ svg, size, level, color, thickness, startAngle, endAngle }) {
        // we assume that the thickness is the same 
        // we dont animated thickness

       
        if (typeof last.size === "undefined") {
            // just update with current values
            last = {
                size, level, startAngle, endAngle
            }
        }

        // compute arrowPath 
        // actually draw arrow and perform animation
        var ringPath = drawRing({
            svg, size, level, color, thickness, startAngle, endAngle, last
        })

        // save last values on end 
        // last = {
        //     size, level, startAngle, endAngle, ringPath
        // }
    }
}

function drawRing({ svg, size, level, color, thickness, startAngle, endAngle, last }) {
    var halfWidth = size.width / 2;
    var halfHeight = size.height / 2;

    var arc = d3.svg.arc()
        .innerRadius(halfWidth - 20 - (level * (5 * thickness)))
        .outerRadius(halfWidth - 20 - ((level + 1) * (5 * thickness)))
        .startAngle(last.startAngle * Math.PI / 180)
        .endAngle(last.endAngle * Math.PI / 180)

    svg.append('g')
        .attr('transform', 'translate(' + halfWidth + ', ' + halfHeight + ')')
        .append('path')
        .attr('id', 'ring')
        .style('fill', color)
        .attr('d', arc)

    var updatedLast = {
            size, level, startAngle, endAngle
    }

    var closestStartAngle = findClosestAngle(last.startAngle, startAngle);
    var closestEndAngle = findClosestAngle(last.endAngle, endAngle);
//    var closestStartAngle = (startAngle - 90)
//    var closestEndAngle = (endAngle - 90) ;

    d3.select('#ring')
        .transition()
        .duration(5000)
        .attrTween("d", function (d, i, a) {
            return function (t) {
                var s = last.startAngle + (closestStartAngle) * t;
                var e = last.endAngle + (closestEndAngle) * t;

                var arc = d3.svg.arc()
                    .innerRadius(halfWidth - 20 - (level * (5 * thickness)))
                    .outerRadius(halfWidth - 20 - ((level + 1) * (5 * thickness)))
                    .startAngle(s * Math.PI / 180)
                    .endAngle(e * Math.PI / 180)

                if (t == 1) {
                    last.size = updatedLast.size;
                    last.level = updatedLast.level;
                    last.startAngle = updatedLast.startAngle;
                    last.endAngle = updatedLast.endAngle;
                }

                return arc();
            }
        })

    return arc;
}

export {
    createAnimatedRing
}


