
import {helpItems as krapkowiceHelpItems} from 'app/legacy/react/eusluga/components/KrapkowiceHelpPanel.jsx'
import {helpItems as armaagHelpItems} from 'app/legacy/react/eusluga/components/ArmaagHelpPanel.jsx'

import {getAllLocations} from 'app/graphql/meteopanel//query/client/UtilityQuery';


var Armaag = require('app/legacy/react/eusluga/components/qualityindex/Armaag.jsx')
var Krapkowice = require('app/legacy/react/eusluga/components/qualityindex/Krapkowice.jsx');



const App = {
  globalConfig: {
    aqindex: {
      type: 'gios'
    }
  }
}


function getIndexDescription(level) {
  var conf = App.globalConfig;

  var table = krapkowiceHelpItems;

  if (conf.aqindex.type == "armaag") {
    table = armaagHelpItems;
  }

  if (typeof level == "undefined") {
    level = table.length - 1;
  }

  return (table[level].title);
}

function getIndexColor(level) {
  var conf = App.globalConfig;

  console.log('reading color for level: ', level)
  var func = Krapkowice.getIndexColor;

  if (conf.aqindex.type == "armaag") {
    func = Armaag.getIndexColor;
  }

  return func(level)
}

// unfortunately ranges are specified in different way and we cannot use the same algorithm 
function checkRange_krapkowice(table, value, key) {
  var level = table.length - 1;


  if (key == "co") {
    // co is defined in mg/m3 but is measured with ug/m3
    value = 0.001 * value;
  }


  for (var i = 0; i < table.length; i++) {
    console.log('testing range for: ', value, key, i, table[i].range)

    var min = Math.floor(table[i].range[key][0]);
    var max = Math.floor(table[i].range[key][1]);

    // just a fix for 0
    if (min == 0) min--;

    if (value > min && value <= max) {
      level = i;
      break;
    }
  }


  
  return level;
}

// PM or GLOBAL
const INDEX_TYPE = "GLOBAL";


function hasIndexLevelsDefined(key) {
  var conf = App.globalConfig;
  // defaults 
  var table = krapkowiceHelpItems;
  
  if (conf.aqindex.type == "armaag") {
    // armaag
    table = armaagHelpItems;
    
    // this is not needed as range is specified in the same now
    //checkRange = checkRange_armaag;
  }

  return (table[0].range.hasOwnProperty(key)) 
}

function getIndexFromTable(key, value) {
  var conf = App.globalConfig;

  // defaults 
  var table = krapkowiceHelpItems;
  var checkRange = checkRange_krapkowice;
  var level = table.length - 1;

  if (conf.aqindex.type == "armaag") {
    // armaag
    table = armaagHelpItems;
    
    // this is not needed as range is specified in the same now
    //checkRange = checkRange_armaag;
  }

  if (!isNaN(parseFloat(value)) && table[0].range.hasOwnProperty(key)) {
    level = checkRange(table, value, key);
  }

  var undef;
  // console.log('level for: ', key, value, level)
  return {
    level: level == table.length - 1 ? undef : level
  }
}


export function computeInfoData(ctx, data) {
  var result = {};
  var chartData = data[data.length - 1];

   console.log('computeInfoData: ', chartData)


  //var locations = getAllLocations();

  //console.log('my locations: ', locations)

  ctx.site.map((site, i) => {
    //var measurements = store.getState().measurements.filter((r, i) => codes.some((a) => a == r.code));
    //var measurements = ctx.getStore('measurement_by_physical_device_id').getRecordById(dev.id);
    //var measurements = ctx.getDeviceMeasurements(dev.id);
    var measurements = ctx.getSiteMeasurements(site.code);
    var out = {};

    // default is brak danych 
    var globalIndex;

    //console.log('computeInfoData: measurements for site: ', measurements, site)
    measurements.forEach((r, i) => {
      var key = r.compound_type;
      var c = "_" + r.code.replaceAll('.', '_');

      if (key == "parametr") {
        key = r.code.split('.')[1];
      }

      //console.log('testing dev', site, measurements, chartData, c, chartData[c])
      //console.log('mapping measurement: ', site, key, r.code, c)
            
      // we need to get the reading 
      var qualityIndex = chartData[c] && getIndexFromTable(key, chartData[c]).level;

      out[key] = Object.assign({
        reading: null, value: null, qualityIndex: null
      }, {
        value: chartData[c],
        qualityIndex: qualityIndex,
        hasIndexLevelsDefined: hasIndexLevelsDefined(key),
        qualityIndexColor: getIndexColor(qualityIndex)
      })

      if (out[key].hasIndexLevelsDefined && !isNaN(parseFloat(chartData[c])) && out[key].qualityIndex > (typeof globalIndex !== "undefined" ? globalIndex: -1 )) {
       // console.log('updating global index with: ', chartData[c], key, out[key].qualityIndex)
        globalIndex = out[key].qualityIndex;
      }
    })

    var pm10 = out.pm10 || {value: null};
    var pm25 = out.pm25 || {value: null};

    if (INDEX_TYPE == "PM") {
      var qualityIndex = 0;

      if (!isNaN(pm10.qualityIndex)) {
        qualityIndex = pm10.qualityIndex;
      }

      if (!isNaN(pm25.qualityIndex)) {
        qualityIndex = pm25.qualityIndex;
      }

      if (!isNaN(pm10.qualityIndex) && !isNaN(pm25.qualityIndex)) {
        qualityIndex = pm10.qualityIndex > pm25.qualityIndex ? pm10.qualityIndex : pm25.qualityIndex;
      } 
    } else {
      // compute index from all measurements 
      qualityIndex = globalIndex;
    }

    //qualityIndex = 2;
    // console.log('getting quality index color for: ', pm10, pm25, qualityIndex)

    result[site.code] = Object.assign({}, out, {
      timestamp: chartData.t,
      qualityIndex: qualityIndex, qualityIndexColor: getIndexColor(qualityIndex), qualityIndexDescription: getIndexDescription(qualityIndex)
    });
  
    console.log('my info data: ', out, result[site.code])
  })

  return result;
}
