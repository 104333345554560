
// this could be wrapped inside something like app/Meteo.Stores
import Stores from 'app/graphql/meteopanel/context/MeteoPanelStores';


export const MemoryCacheFieldsPolicy = {
    typePolicies: { // Type policy map
        // disabling cache normalization 
        // SearchResult: {
            // keyFields: false
        // },
        location: {
            fields: { // Field policy map for the Product type
                testField(name, { args }) { // Field policy for the isInCart field
                        console.log('collection reading field: ', name, args, Stores)

                        return "HELLO"

                        //return coll && coll.filter((dev) => new RegExp(filter).exec(dev.name));

                        // return localStorage.getItem('CART').includes(
                        //     variables.productId
                        // );
                   
                }
            }
        }
    }
}