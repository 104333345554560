import { gql } from 'apollo-boost';


function createTableDataQuery({dataUrl, tableName}) {
    // we should put constant queries in const variables 
    return gql`
    {
        tableData( url: "${dataUrl}", tableName: "${tableName}" ) @client {
            id code name agg_unit decimals format compound_type
        }      
    }
    `;
}

export default createTableDataQuery;