
import {findClosestAngle} from './Utils'

var d3 = require('d3');


function createAnimatedArrow() {
    var last = {
        //size: null, angle: null, length: null
    }

    return function ({ svg, size, angle, length }) {
        var halfHeight = size.height / 2;

        // this is adjusted experimentally 
        // it makes sense because the ring has width = 2
        var radius = halfHeight - 24;

        // this assignment is used for testing 
        //  length = 2

        // this can be used to animate arrow, and it will track old values automatically 
        if (length < 0.09) {
            length = 0.09;
        }

        // length is in radiuses (1 = 7.5 m/s) = radius
        // we are setting it here because its easier to manage state 
        length *= radius;
        //length += 10;

        if (typeof last.size === "undefined") {
            // just update with current values
            last = {
                size, angle, length
            }
        }

        // compute arrowPath 
        // actually draw arrow and perform animation
        var arrowPath = drawArrow({
            svg, size, angle, length, last
        })

        // // save last values on end 
        // last = {
        //     size, angle, length, arrowPath
        // }
    }
}


function arrow_computeLine({ radAngle, radius, length }) {
    var len = length;
    return {
        x1: Math.cos(radAngle) * radius,
        y1: Math.sin(radAngle) * radius,
        x2: Math.cos(radAngle) * (radius - len),
        y2: Math.sin(radAngle) * (radius - len)
    }
}


function arrow_defs({ svg, color = 'red' }) {

    // just an arrow definition 
    svg.append('svg:defs')
        .append('svg:marker')
        .attr('id', 'markerArrow')
        .attr('markerHeight', 3)
        .attr('markerWidth', 3)
        .attr('markerUnits', 'strokeWidth')
        .attr('orient', 'auto')
        .attr('refX', 0)
        .attr('refY', 0)
        .attr('viewBox', '-5 -5 10 10')
        .append('svg:path')
        .attr('d', 'M 0,0 m -5,-5 L 5,0 L -5,5 Z')
        .attr('fill', color);
}


/* 
    svg - svg reference
    size - canvas size 
    angle - angle in degrees (0 pointing North)
    length - length of and arrow in specific units 
    animParams - old parameteres for animation purposes
*/


function drawArrow({ svg, size, angle, length, last }) {


    var halfWidth = size.width / 2;
    var halfHeight = size.height / 2;
    var radius = halfHeight - 20;
    var radAngle = (angle - 90) * Math.PI / 180;
    var color = 'red';
    
    var line = arrow_computeLine({
        radAngle, radius, length
    })

    var arrowPath = 'M' + line.x1 + ',' + line.y1 + 'L' + line.x2 + ',' + line.y2;
    var updateLast = {
        size, angle, length, arrowPath
    }

    // svg defs 
    arrow_defs({svg})

    // actual arrow 
    svg.append('g')
        //.append('id', 'svg-arrow')
        .append('svg:path')
        .attr('id', 'p1')
        .attr('marker-end', 'url(#markerArrow)')
        .attr('stroke', color)
        .attr('fill', color)
        .attr('stroke-width', 6)
        //.attr('stroke-linecap', 'round')
        // assuming center 
        .attr('transform', 'translate(' + halfWidth + ', ' + halfHeight + ')')
        .attr('d', last.arrowPath || arrowPath)

    var closestAngle = findClosestAngle(last.angle, angle);

    d3.select('#p1')
        .transition()
        .duration(5000)
        .attrTween("d", function (d, i, a) {
            return function (t) {
                var angle = last.angle + (closestAngle) * t;
                var radAngle = (angle - 90) * Math.PI / 180;
                var len = last.length + (length - last.length) * t;
                var line = arrow_computeLine({radAngle, radius, length: len})

           
                if (t == 1) {
                      // save last values on end 
                
                    last.angle = updateLast.angle;
                    last.size = updateLast.size;
                    last.length = updateLast.length;
                    last.arrowPath = updateLast.arrowPath;
                }


                return 'M' + line.x1 + ',' + line.y1 + 'L' + line.x2 + ',' + line.y2;
            }
        })

    // we return comptued arrowPath for future animation 
    return arrowPath;
}

export {
    createAnimatedArrow, arrow_computeLine
}