import React, { Component, useMemo } from 'react';
import { MeteoPanelContext } from '../context/MeteoPanelContext';
import { Query } from 'react-apollo';

// legacy utilities libraries
import {
    buildSerieSpec, dateToUts, roundDate, nextDate, buildQueryVars
} from '../../fey/data/FeyUtils';

// import queries
import createChartDataQuery from '../query/client/ChartData';

// import new components
import HorizontalTabs from './HorizontalTabs';
import Chart from '../../../legacy/react/components/Chart.jsx';

import { getIndexIcon } from 'app/legacy/react/eusluga/components/qualityindex/Krapkowice.jsx';

// import styled from "styled-components"
// import tw from "twin.macro"
 import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'


// var loaderImage = require('app/images/chart-loader.gif').default;

var LoaderComp = () =>
    <Dimmer active inverted>
        <Loader inverted>Ładowanie danych...</Loader>
    </Dimmer>
//  <div style={{position: 'absolute',top: 0, left: 'calc(50% - 30px)', background: '#eee', opacity: 0.7 }}> 
// </div>

// Moment js
var Moment = require('moment');
Moment.locale('pl');


class LiveDataChart extends Component {

    state = {
        selectedTab: null
    }

    loading = false;

    onTabItemClick(item) {
        console.log('item clicked: ', item) 

        this.setState({
            selectedTab: item
        })
    }

    generateView(ctx, { loading, error, data }) {
        if (error) return `Error! ${error.message}`;
        console.log('generate rendering chart: ', data, data && data.chartData == this.lastChartData, loading, this.loading, this.loading == loading)

        let params = [loading, data, ctx.ui.viewMode, ctx.ui.historyMode.now1h, ctx.now10m ];

        if (this.memoizedComponent) {
            if (this.lastParams && !this.lastParams.some((v, i) => v !== params[i])) {
                // we should use memoized component 
                return this.memoizedComponent;
            }
        }

        this.lastParams = params;

        var properValue = false;

        if (data) {
            var lastValue = data.chartData[data.chartData.length - 1];

            // check for proper value 
            for (var p in lastValue) {
                if (p !== 't' && p !== '__typename') {
                    if (lastValue[p] !== '') {
                        // we assume that there is a value for this param 
                        properValue = true;
                    }
                }
            }

            //console.log('setting last chart data: ', this.lastChartData == data.chartData, this.lastChartData, data.chartData)
            this.lastChartData = data.chartData;

            if (properValue) {
                
                // save selected tab 
                this.lastSelectedTab = this.state.selectedTab;
            }
        }

        // 
        this.loading = loading;

        // console.log('regenerating chart view: ', new Date(), this.props.tabsConfig[0], data)
        var color = [];
        var selectedTab = this.lastSelectedTab || this.props.defaultTab;
        var codes = [...selectedTab.measurements];

        if (this.lastChartData) {

            // we need to remember that we are using _ to name gql columns 

            codes.forEach((c) => {
                var meas = ctx.getMeasByCode(c);

                if (meas) {
                    var compound = ctx.getCompoundType(
                        meas.compound_type
                    );

                    if (compound) {
                        color.push(compound.color);
                    }
                }
            })

            //console.log('should update context: ')
            // setTimeout(() => ctx.setState({lastChartData: this.lastChartData}), 100)
        }

        //setTimeout(() => this.forceUpdate(), 0)

        // this is for horizontal tabs 
        var {
            dataUrl, tabsConfig, defaultTab, buttons
        } = this.props;

        var icon;
        var selectedTab = this.state.selectedTab || defaultTab;
        var measurements = selectedTab.measurements;
        var code = tabsConfig[0].code;

        if (tabsConfig.length == 1 && ctx.infoData.hasOwnProperty(code)) {
            // we have only one headers so this is a single chart with physical_device_id set

            //console.log('getting index icon in horizontal tabs in chart view: ', ctx.infoData[id])
            icon = getIndexIcon({}, ctx.infoData[code])

            if (icon) {
                this.infoData = ctx.infoData[code];
            }
        }

        //console.log('creating chart with: ', this.lastChartData, properValue  , selectedTab, ctx.ui.charts.selectedRange)
        properValue = true;

        // try to save the reference inside a context 
        // without using setState ... (we just want to silently set the reference (i know its bad but fuck it)) 
        // console.log('setting reference to chart: ', ctx, this.props.name, this)
        //     context.setState({ ui: { refs: { [this.props.name]: this } } });
        ctx.ui.refs[this.props.name] = this; 
                    

        console.log('rendering chart: ', this.lastChartData, properValue, loading) 
        return (
            this.lastChartData && properValue ?
                this.memoizedComponent = [
                    <HorizontalTabs key={"tabs"} selectedTab={selectedTab.name} tabs={tabsConfig} buttons={buttons} buttonsHandler={(label) => ctx.setState({ui: {charts: {selectedRange: label}}})} selectedButton={ctx.ui.charts.selectedRange} onItemClick={this.onTabItemClick.bind(this)} icon={icon} />,
                    <div key={"chart_content"} style={{ padding: 0, height: 240, position: 'relative', display: 'block'}}>
                        {/* we use color as table, we can process this */}
                        <Chart dataProvider={ctx} key={"chart_" + measurements.join('_')} disableMouseEvent={false} data={this.lastChartData} code={measurements} avg={"10m"} viewMode={ctx.ui.viewMode} onMouseMoveHandler={ctx.ui.viewMode == "history" ? this.props.onMouseMoveHandler : false} selectionData={this.props.selectionData}/>
                        {loading ? <LoaderComp/> : null}
                        {/* <LoaderComp /> */}
                    </div>
                ]
                : null
        )
    }

    
    render() {
        var {
            dataUrl, tabsConfig, defaultTab
        } = this.props;

        var step = '1h';
        var selectedTab = this.state.selectedTab || defaultTab;

        //console.log('my chart measurements: ', selectedTab, columns);

        var columns = selectedTab.measurements;


        return (
            <MeteoPanelContext.Consumer key={"chart"}>
                {(context) => {
                    var rangeHours = 12;

                    switch (context.ui.charts.selectedRange) {
                        case "12h": 
                            rangeHours = 12;
                            break;
                        case "3d": 
                            rangeHours = 24 * 3;
                            break;
                        case "7d": 
                            rangeHours = 24 * 7;
                            break;
                    }

                    return (
                        context.measurement.length && context.now ?
                            <Query fetchPolicy={"network-only"} query={
                                createChartDataQuery({
                                   // mem: 1,
                                    dataType: 'averages',
                                    dataUrl,
                                    uts1: context.now10m - rangeHours * 3600, uts2: context.now10m,
                                    step,
                                    columns,
                                    vars: buildQueryVars(columns, step, context.measurement), // server query vars
                                    context // pass context to query function 
                                })
                            }>{this.generateView.bind(this, context)}</Query> :  <LoaderComp/>
                    )
                }}
            </MeteoPanelContext.Consumer>

        )
    }
}

export default LiveDataChart;

