

const MAX_VALUE = 9999999999999;


const helpItems = [
  {
    title: "BARDZO DOBRE",
    color: "#2D99CD",
    desc: "Jakość powietrza jest bardzo dobra. Warunki bardzo sprzyjające do wszelkich aktywności na wolnym powietrzu bez ograniczeń.",
    range: {
      pm10: [0, 20],
      pm25: [0, 13],
      o3:   [0, 70],
      no2:  [0, 40],
      so2:  [0, 50],
      benzen: [0, 6],
      co: [0, 3]
    }
  },
  {
    title: "DOBRE",
    color: "#EAE9E8",
    desc: "Jakość powietrza jest dobra. Można przebywać na wolnym powietrzu i wykonywać dowolną aktywność bez ograniczeń.",
    range: {
      pm10: [20.1, 50],
      pm25: [13.1, 35],
      o3:   [70.1, 120],
      no2:  [40.1, 100],
      so2:  [50.1, 100],
      benzen: [6.1, 11],
      co:   [3.1, 7]
    }
  },
  {
    title: "UMIARKOWANE",
    color: "#6EA837",
    //desc: "Jakość powietrza jest akceptowalna. Warunki umiarkowane do aktywności na wolnym powietrzu.",
    desc: "Jakość powietrza jest akceptowalna. Osoby wrażliwe ( dzieci, osoby starsze,kobiety w ciąży) powinny ograniczyć aktywność ruchową na zewnątrz.",
    range: {
      pm10: [50.1, 80],
      pm25: [35.1, 55],
      o3:   [120.1, 150],
      no2:  [100.1, 150],
      so2:  [100.1, 200],
      benzen: [11.1, 16],
      co:   [7.1, 11]
    }
  },
  {
    title: "DOSTATECZNE",
    color: "#EDC410",
    //desc: "Jakość powietrza jest dostateczna. Należy rozważyć ograniczenie (skrócenie lub rozłożenie w czasie) aktywności na wolnym powietrzu.",
    desc: "Jakość powietrza jest dostateczna. Należy ograniczyć (skrócenie w czasie) aktywności na wolnym powietrzu  dla osób wrażliwych.",
    range: {
      pm10: [80.1, 110],
      pm25: [55.1, 75],
      o3:   [150.1,180],
      no2:  [150.1,200],
      so2:  [200.1,350],
      benzen: [16.1, 21],
      co:   [11.1, 15]
    }
  },
  {
    title: "ZŁE",
    color: "#EA9412",
    desc: "Jakość powietrza jest zła. Ograniczyć do minimum wszelką aktywność fizyczną na wolnym powietrzu.",
    range: {
      pm10: [110.1, 150],
      pm25: [75.1, 110],
      o3:   [180.1, 240],
      no2:  [200.1, 400],
      so2:  [350.1, 500],
      benzen: [21.1, 51],
      co:   [15.1, 21]
    }
  },
  {
    title: "BARDZO ZŁE",
    color: "#CB0B1C",
    desc: "Jakość powietrza jest bardzo zła i ma negatywny wpływ na zdrowie. Ograniczyć przebywanie na wolnym powietrzu do niezbędnego minimum.",
    range: {
      pm10: [150.1, MAX_VALUE],
      pm25: [110.1, MAX_VALUE],
      o3: [240.1, MAX_VALUE],
      no2: [400.1, MAX_VALUE],
      so2: [500.1, MAX_VALUE],
      benzen: [51.1, MAX_VALUE],
      co: [21.1, MAX_VALUE]
    }
  },
  {
    title: "BRAK DANYCH",
    color: "#EBE8DE",
    desc: "Nie ma wystarczających danych wymaganych do określenia poziomu jakości powietrza.",
    range: {
    }
  },


]



export {
  helpItems
}