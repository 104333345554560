// bootstrap code goes here
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// this will add support for css element queries
// we need only resize sensor to effectivelly get the element size
import { ResizeSensor } from 'css-element-queries';
import ChartSeries from '../chart/ChartSeries.jsx';


// jquery library
// import JQuery from 'jquery';

// window.$ = window.jQuery = JQuery;

// Object.assign: babel-plugin-object-assign
// require('object-assign');
var Moment = require('moment');
Moment.locale('pl');

var _ = require('lodash');
var d3 = require('d3');


var momentLocalizer = require('react-widgets-moment')
momentLocalizer(Moment);

// this will add summing offset parent to properties
window.Object.defineProperty(Element.prototype, 'documentOffsetTop', {
  get: function () {
    return this.offsetTop + (this.offsetParent ? this.offsetParent.documentOffsetTop : 0);
  }
});

window.Object.defineProperty(Element.prototype, 'documentOffsetLeft', {
  get: function () {
    return this.offsetLeft + (this.offsetParent ? this.offsetParent.documentOffsetLeft : 0);
  }
});

var ReactFauxDOM = require('react-faux-dom')


function padValue(text, { count = 5 }) {
  return text;
  var result = _.padStart(text, count, "_").replace(/_/g, "&nbsp");
  console.log('padding value: ', text, count, result)
  return result;
}

function generateTickValues1(domain, width) {
  var steps = [2, 3, 4, 6, 12, 24, 48, 96]; // steps in hours
  var labelWidth = 80;
  var diff = domain[1].getTime() - domain[0].getTime();
  var len = width / diff; // size per millisecond
  var t = domain[0].getTime();
  var l = t;
  var z = 3600 * 1000;
  var s = z;
  var tickValues = [domain[0]];

  var count = (width / labelWidth);

  steps.some((r, i) => {
    r *= z;
    s = r;
    return (r * count) <= width;
  })

  t = Math.floor(domain[0].getTime() / (s)) * s;



  return domain;
}

function generateTickValues(domain, width) {
  //  debugger

  var steps = [2, 3, 4, 6, 12, 24, 48, 96]; // steps in hours
  var labelWidth = 50;
  var diff = domain[1].getTime() - domain[0].getTime();
  var len = width / diff; // size per millisecond

  var t = domain[0].getTime();
  var l = t;
  var z = 3600 * 1000;
  var s = z;
  var tickValues = [domain[0]];

  var count = (width / labelWidth);
  var td = new Date(t);

  steps.some((r, i) => {
    r *= z;
    s = r;

    td.setHours(domain[0].getHours() - (domain[0].getHours() % r));
    t = td.getTime();

    return (diff / r) * labelWidth <= width;
  })

  //console.log('my first domain value: ', domain[0], new Date(t))


  var first = 1;
  while (t < domain[1].getTime()) {
    t += s;

    if (first && (t - domain[0].getTime()) * len >= labelWidth) {
      tickValues.push(new Date(t));
      first = 0;
    } else if ((domain[1].getTime() - t) * len <= labelWidth) {
      tickValues.push(domain[1])
    } else if (!first) {
      tickValues.push(new Date(t))
    }
  }

  //console.log('my tickValues: ', tickValues, s / (3600 * 1000), count)

  return tickValues;
}

// http://www.adeveloperdiary.com/react-js/how-to-integrate-react-and-d3-the-right-way/

// use react faux dom  to create virtual elements and return react elementsk
// https://github.com/Olical/lab/blob/gh-pages/js/react-faux-dom-state/main.j
// example of styling:
// http://bl.ocks.org/vjpgo/4687320

// binary search
// http://oli.me.uk/2013/06/08/searching-javascript-arrays-with-a-binary-search/
// https://www.npmjs.com/package/binary-search

// customize time format:
// http://fengshuo.co/2015/05/24/d3-custom-time-scale-and-axis/

// all about d3 time scales:
// http://bl.ocks.org/jebeck/9671241

// smoothing out lines
// http://www.d3noob.org/2013/01/smoothing-out-lines-in-d3js.html

// animated line:
// https://jakearchibald.com/2013/animated-line-drawing-svg/

//import mixins from 'es6-mixins'
// TODO:
// Plane description:   {x, y, width, height}
// (Time, Value) Domain description:  {min, max}

// planeToDomain(point) returns domainObject;
// domainToPlane(domainObject) returns point;

var DefaultLegendComponent = (props) => {
  return <div style={{ width: '100%', height: 24, top: 0, background: '#fefefe', zIndex: 2 }}>
    {
      props.series.map((r, i) => {
        return <div key={i} className="legend-item" style={{ float: 'right', marginRight: 20 }}>
          <div key={2} style={{ marginRight: 10, float: 'left' }}><span className='legend-item-value' dangerouslySetInnerHTML={{ __html: r.valueAccessor(r.data[0]) }} /></div>
          <div key={0} style={{ width: 10, float: 'left', border: '1px solid black', height: 10, marginTop: 3, marginRight: 5 }}>
            <div style={{ width: 8, float: 'left', height: 8, marginTop: 1, marginLeft: 1, background: r.style.stroke }} />
          </div>
          <div key={1} style={{ float: 'left' }}><span dangerouslySetInnerHTML={{ __html: r.legendName }} /></div>
        </div>
      })
    }
  </div>;
};

class LineChart extends Component {

  constructor(props) {
    super();

    // odpalaja sie od dupy strony
    //mixins([MyFancyMixin, MyFancyMixin2].reverse(), this);

    // {
    //   "warn": true, // defaults to true
    //   "mergeDuplicates": true // defaults to true
    // })

    this.legendItems = [];
    this.lastResizeEvent = null;

    this.state = {
      width: props.width,
      height: props.height
    }

    this.refs = {
      svg: null
    }
  }

  componentWillReceiveProps(props) {
    // react on change of properties
    this.onResize(this.lastResizeEvent)
  }

  createD3Path(svg, serie, i, size, c) {

    // update series scales
    // we could do this somewhere else but for now its enough
    serie._updateScales(size);

    //////console.log('faux: ', value_extent, time_extent, time_scale(serie.data[0].t), serie.data[1] ? time_scale(serie.data[1].t): null)
    if (serie.scale.visible) {
      var scalePos = serie.scale._x;
      var tickSize = serie.scale._tickSize;

      //console.log('drawing scale: ', tickSize, serie.scale.orient, scalePos, serie.scale)
      //console.log('drawing scale: ', serie.scale)
      var axis = d3.svg.axis()
        .scale(serie.scale.d3scale)
        .tickPadding(5)
        //      .ticks(isNaN(serie.scale.tickSize)? 5 : serie.scale.tickSize)
        .ticks(this.state.scaleTickSize)
        .orient(serie.scale.orient)
        .tickSize(tickSize, 0)
      //  .nice();

      svg.append('g').attr('class', 'axis y ' + i)
        .attr("transform", "translate(" + scalePos + ", 0)")
        .style({
          //     'fill': serie.style.stroke,
          //  'stroke-width': '1px',
          //   'stroke': 'grey',
          'font-family': 'sans-serif',
          'font-weight': 'normal',
          'font-size': '12px'
        }).call(axis);


      var ticks = svg.selectAll('.axis.y .tick  text');
      var text = ticks[0][ticks[0].length - 1]

      //  if (text)
      //    text.props.onClick = (event) => console.log('clicked: ', event)
    }


    if (i == 0) {
      // TODO czy d3 locale ma tak pozostac?
      var pl_PL = {
        "decimal": ",",
        "thousands": "",
        "grouping": [3],
        "currency": ["zł", ""],
        "dateTime": "%d.%m.%Y %H:%M:%S",
        "date": "%d.%m.%Y",
        "time": "%H:%M:%S",
        "periods": ["AM", "PM"],
        "days": ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"],
        "shortDays": ["nd", "pn", "wt", "śr", "cz", "pt", "so"],
        "months": ["styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"],
        "shortMonths": ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "paź", "lis", "gru"]
      };
      var PL = d3.locale(pl_PL);
      // var formatter = serie.timeline.formatter ? serie.timeline.formatter : d3.time.format(serie.timeline.format);

      var xAxis = d3.svg.axis()
        .scale(serie.timeline.d3scale)
        .orient('bottom')
        //  .ticks(d3.time.days, 1)
        .tickFormat(PL.timeFormat(serie.timeline.format))
        // .tickFormat((ts) => {
        //   //////console.log('parsing ts: ', ts)
        //   return formatter(ts)
        // })
        .tickPadding(10)
        //  .tickValues(serie.timeline.tickValues)
        //  .tickSize(0)
        //.ticks(this.state.timelineTicksCount)
        //.tickValues(serie.timeline.d3scale.domain())
        .tickValues(generateTickValues(serie.timeline.d3scale.domain(), size.width))
        //  .tickValues(d3.range(1, 24, 1))
        //  .ticks(6)
        //  .tickSubdivide(2)
        .tickSize(-size.height, 0);


      if (this.props.series.guideLine) {
        svg.append('g').style({
          stroke: '#dddddd',
          'stroke-width': '1px',
          'stroke-dasharray': '10,5'
        }).append('path').attr({
          data: '', class: 'guide_line'
        })

        svg.append('g').style({
          stroke: '#dddddd',
          'stroke-width': '1px',
          'stroke-dasharray': '10,5'
        }).append('path').attr({
          data: '', class: 'vguide_line'
        })

      }

      if (this.props.series.levels) {
        // we should create levels
        this.props.series.levels.forEach((r, i) => {
          svg.append('g').style({
            stroke: r.style.color,
            'stroke-width': '1px',
            'stroke-dasharray': '10,5'
          }).append('path').attr({
            data: '', class: 'level_line' + ' level_' + i
          })
        })
      }


      //  console.log('generating time scale: ', size.x, size.width)
      svg.append('g').attr('class', 'axis x')
        .attr("transform", "translate(0, " + (size.height + size.margin.top) + ")")
        .style({
          //   'shape-rendering': 'crispEdges',
          //  'stroke': 'grey',
          'font-family': 'sans-serif',
          'font-size': '12px',
          'font-weight': 'normal !important',
          'fill': 'black',
          //  'stroke-width': '1px',
          'font-variant': 'normal',
          'font-weight': 'normal'
        })
        .call(xAxis);

      svg.selectAll('.axis .tick line').style({
        stroke: '#dddddd', 'stroke-width': '1px'
      })

      if (this.props.series.guideLine) {
        svg.append('g')
          .attr({
          })
          .append('text')
          .attr({
            class: 'guide_text',
            fill: 'grey',
            width: '100px',
            'font-size': '10px',
          })
          .text('')
      }

    }

    // svg.append('g').attr({
    //   fill: 'none', key: index, class: "chart_line line_" + index,
    // }).append('path').attr({
    //   'd' : line(data), 'shape-rendering' : 'crispEdges'
    // }).attr(Object.assign({}, serie.style)).on('mouseenter', this.onPathMouseEnter.bind(this, index))
    // .on('mouseout', this.onPathMouseOut.bind(this, index))

    //console.log('my series length: ', this.props.series.length)

    serie._draw(svg, size, i, this.props.series.length);

    if (!this.props.disableMouseEvent) {
      svg.append('g').attr({
        fill: serie.style.stroke
      }).append('circle').attr({
        cx: 0, cy: 0, r: 3, class: 'dot' + ' dot_' + i
      })
    }


    // svg.append('g')
    //   .attr('key', 'legend')
    //   .attr('class', 'legend')
    //   .attr('transform', 'translate(50, 30)')
    //   .style('font-size', '12px')
    //   .call(d3.legend)
  }

  onPathMouseOut(i) {
    ////console.log('selected path: ', i)
    ////console.log('selected object: ', d3.select(this.refs.svg).select(".line_" + i + " path"))
    var path = d3.select(this.refs.svg).select(".line_" + i + " path");

    path.style(this.props.series[i].style);
  }

  onPathMouseEnter(i) {

    ////console.log('selected path: ', i)
    ////console.log('selected object: ', d3.select(this.refs.svg).select(".line_" + i + " path"))

    //
    // var path = d3.select(this.refs.svg).select(".line_" + i + " path");
    //
    // path.style({
    //   //  'stroke': 'black',
    //   'stroke-width': 10
    // })
  }

  onSvgMouseMove(event, force) {

    //console.log('hello world:', event, new Date())


    if (event == null || typeof event == "undefined") {
      return;
    }

    // NOTICE: this is debatable, i dont know why this works on old code
    // if (event.target.nodeName != "svg") {
    //   return
    // }


    //
    // if (force) {
    //   console.log('disable svg mouse move with force: ', event, event.target.nodeName, force, this.props.disableMouseEvent)
    // }

    var nativeEvent = event.nativeEvent;

    this.lastMouseEvent = Object.assign({}, event);

    // console.log('svg mouse move', event.nativeEvent.offsetX, event.nativeEvent.offsetY)
    // linear.invert(y)

    var circle = d3.select(this.refs.svg).selectAll('.dot')
    var date = d3.select(this.refs.svg).selectAll('.date');
    var guideLine = d3.select(this.refs.svg).selectAll('.guide_line');
    var valueGuideLine = d3.select(this.refs.svg).selectAll('.vguide_line');
    var levelLine = d3.select(this.refs.svg).selectAll('.level_line');
    var guideText = d3.select(this.refs.svg).selectAll('.guide_text');


    // console.log('circles: ', circle )
    function DataModel(t, v) {
      this.t = t;
      this.v = v;
    }

    var qarr = [];
    var mousePosition = 0;
    var selectionData = {
      series: [this.props.series.map((s) => {})],

      // lets just create fake event with an offsetX 
      event: {
        target: { nodeName: 'svg' }, nativeEvent: {
          offsetX: event.nativeEvent.offsetX
        }
      }
    }

    circle.attr('cx', (d, i) => {
      if (i >= this.props.series.length) {
        return 0;
      }

      var serie = this.props.series[i];
      var q = serie._x2i(event.nativeEvent.offsetX);
      var a = serie.data[q];

      qarr[i] = q;

      var ret = serie._t2x(serie.timeAccessor(a));

      // update selection data 
      selectionData.series[i] = {
        q, a, x: ret, t: serie.timeAccessor(a), v: serie.scale.d3scale(serie.valueAccessor(a))
      }

      //selectionData.event = event;

      if (i == 0) {
        mousePosition = ret;
      }
      //  console.log('cx for ', event.nativeEvent.offsetX, i, ret, q, a, serie.data.length);
      return ret;
      //return event.nativeEvent.offsetX;
    }).attr('cy', (d, i) => {
      if (i >= this.props.series.length) {
        return 0;
      }
      var serie = this.props.series[i];
      var value_scale = serie.scale.d3scale;

      var a = serie.data[qarr[i]];
      var ret = value_scale(serie.valueAccessor(a));

      if (/.*WD/.test(serie.name)) {
        ret = -10;
      }

      // console.log('what is legend-item: ', this.legendItems[i]
      //console.log('legend-item-value: ', this.legendItems[i].getElementsByClassName('legend-item-value'))
      // first elemnet for value

      var val = serie.valueAccessor(a);
      val = Math.floor(val * 100) / 100;

      var legendValueItem = this.legendItems[i].getElementsByClassName('legend-item-value')[0];

      val = isNaN(val) ? padValue('-', 5) : padValue(val, 5);

      if (legendValueItem.innerHTML != val) {
        legendValueItem.innerHTML = val;
      };

      //console.log('my last size: ', this.lastSize)

      //    this.legendDateItem.style.marginLeft = size.x;
      //    this.legendDateItem.style.marginTop = size.margin.top;

      if (i == 0) {

        //  console.log('my legend date item: ', this.legendDateItem, 35, this.lastSize.height)
        this.legendDateItem.style.top = 35 + this.lastSize.height + "px";
        this.legendDateItem.style.left = mousePosition + "px";
        this.legendDateItem.style.marginLeft = "-8ex";
        this.legendDateItem.style.fontSize = "11px";
        // console.log('my legend date item: ', this.legendDateItem.style)

        var momentFormat = "YYYY-MM-DD HH:00";
        if (serie.timeline.format == "%d %b") {
          momentFormat = "YYYY-MM-DD";
        }

        if (serie.timeline.format == "%d %b %H") {
          momentFormat = "YYYY-MM-DD HH:00";
        }

        if (serie.timeline.format == "%H:%M:%S") {
          momentFormat = "HH:mm:ss";
        }


        // this depends on timeline format specified on higher levels
        this.legendDateItem.innerHTML = Moment(new Date(serie.timeAccessor(a))).format(
          momentFormat
        );

        if (guideLine) {

          // create line generator
          var line = d3.svg.line()
            .x(
              (d) => d.t + 0.5
            ).y(
              (d) => Math.round(serie.scale.d3scale(d.v)) + 0.5
            );

          var timeDomain = serie.timeline.d3scale.domain();
          var valueDomain = serie.scale.d3scale.domain();

          //console.log('my domain: ', serie.scale.d3scale.domain())

          var tempData = [{}, {}].map((r, i) => {
            return { t: mousePosition, v: valueDomain[i] }
          })

          guideLine.attr({
            'd': line(tempData),
          })

          //console.log('my domain: ', serie.scale.d3scale.domain())

          var tempData2 = [{}, {}].map((r, i) => {
            return {
              t: Math.round(serie.timeline.d3scale(timeDomain[i].getTime())) || 0,
              v: isNaN(serie._y2v(event.nativeEvent.offsetY)) ? 0 : serie._y2v(event.nativeEvent.offsetY)
            }
          })
          //console.log('my guide line: ', tempData2)

          if (tempData2[0].v > 0) {
            valueGuideLine.attr({
              'd': line(tempData2)
            })

            guideText.attr({
              y: event.nativeEvent.offsetY,
              x: this.lastSize.x
            })
              .text(
                Math.floor((isNaN(serie._y2v(event.nativeEvent.offsetY)) ? 0 : serie._y2v(event.nativeEvent.offsetY)) * 10) / 10
              )
          }
        }

        //
        //console.log('what is level line: ', levelLine)
        if (levelLine) {
          var levels = this.props.series.levels;

          //console.log('my levels: ', levels)
          levelLine.attr({
            color: function (d, k) {
              return k < levels.length ? levels[k].style.color : ""
            },
            d: function (d, k) {
              if (k >= levels.length)
                return ""
              // create line generator
              var line = d3.svg.line()
                .x(
                  (d) => Math.round(serie.timeline.d3scale(d.t.getTime())) + 0.5
                ).y(
                  (d) => Math.round(serie.scale.d3scale(d.v)) + 0.5
                );

              var timeDomain = serie.timeline.d3scale.domain();
              var valueDomain = serie.scale.d3scale.domain();

              //console.log('my domain: ', serie.scale.d3scale.domain())

              var tempData = [{}, {}].map((r, z) => {
                return { t: timeDomain[z], v: levels[k].value }
              })

              return line(tempData);
            }
          })
        }

      }
      return (isNaN(ret) ? 0 : ret);
    }).style('visibility', (d, i) => {
      if (i >= this.props.series.length) {
        return;
      }
      var serie = this.props.series[i];
      var a = serie.data[qarr[i]];

      var ret = (isNaN(serie.valueAccessor(a)) ? "hidden" : "visible");

      //    console.log('returning value: ', serie.valueAccessor(a), ret);
      return ret;
    })


    try {
      clearTimeout(this.userActivityTimeoutId);
    } catch (er) {

    } finally {

    }

    //console.log('should fire event on mouse move', this.props, this.props.onMouseMoveHandler, selectionData)
    if (this.props.onMouseMoveHandler) {
      if (!this.lastSelectionData || selectionData.series[0].q != this.lastSelectionData.series[0].q) {
        try { clearTimeout(this.lastSelectionEventTimeout) } catch (er) { }

        this.lastSelectionEventTimeout = setTimeout(() => this.props.onMouseMoveHandler(selectionData), 1);
      }

      // save selection data
      this.lastSelectionData = selectionData;
    } else {
      this.userActivityTimeoutId = setTimeout(() => {  //
        if (!this.refs.svg) return;

        var offsetX = this.refs.svg.clientWidth;
        if (this.lastNonNullSample) {
          var serie = this.props.series[0];

          offsetX = serie._t2x(serie.timeAccessor(this.lastNonNullSample))
        }

        //console.log('clientWidth: ', this.refs.svg.clientWidth, 'offsetX: ', offsetX)

        if (this.refs.svg.clientWidth)
          this.onSvgMouseMove({
            target: { nodeName: 'svg' }, nativeEvent: {
              offsetX: offsetX
            }
          })
      }, 10000);
    }
  }

  findLastNonNullSample() {
    var sample = null;

    // this applies only to charts with one serie
    if (this.props.series.length == 1 || 1) {
      var data = this.props.series[0].data;
      var valueAccessor = this.props.series[0].valueAccessor;
      var len = this.props.series[0].data.length;

      for (var i = len - 1; i >= 0 && isNaN(valueAccessor(data[i])); i--) {
        //console.log('my value accessor: ', valueAccessor(data[i]), data[i])
      }

      sample = data[i];
    }

    //console.log('my last non null sample: ', sample)

    return sample;
  }


  createLegend(svg, series, size) {

    // this for react
    var g = svg.append('g').attr({
    })

    var svgEl = document.createElement('svg');
    var textEl = document.createElement('text');
    //  svgEl.style.display = 'none';
    svgEl.appendChild(textEl);
    document.body.appendChild(svgEl);
    textEl.innerHTML = 'dupa';

    //    var bbox = textEl.getBBox();

    console.log('bbox: ', textEl.width, textEl.height)
    g.append('rect').attr({
      class: 'legend_bkg',
      width: '100%',
      height: '20px',
      lineHeight: '20px',
      fill: '#eeeee',
      //      stroke: 'black'
    })

    series.forEach((serie, i) => {

      //
      // console.log('textEl: ', textEl);

      g.append('rect').attr({
        width: '10px',
        key: i + 'dot',
        height: '10px',
        fill: serie.style.stroke,
        //    x: size.width + size.x + size.margin.left - bbox.width,
        x: size.width + size.x + size.margin.left,
        y: 6
        //      stroke: 'black'
      })

      g.append('text').html(serie.legendName).attr({
        key: i + 'name',
        textAnchor: 'end',
        lengthAdjust: 'spacingAndGlyphs',
        //textLength: bbox.width,

        //    listStyle: 'none',
        x: size.width + size.x + size.margin.left,
        y: 18
      })
    })
  }

  //
  // shouldComponentUpdate() {
  //
  //   return 1;
  // }

  componentDidMount() {
    //  this.lastNonNullSample = null;
    this.lastNonNullSample = this.findLastNonNullSample();

    //this.checkComponentSize();
    this.startSizeDetector();

    var ref = ReactDOM.findDOMNode(this);

    this.legendItems = ref.getElementsByClassName('legend-item');
    this.legendContainer = ref.getElementsByClassName('legend-container');
    this.legendDateItem = ref.getElementsByClassName('legend-date')[0];

    setTimeout(() => {
      var offsetX = this.refs.svg.clientWidth;
      if (this.lastNonNullSample) {
        var serie = this.props.series[0];

        offsetX = serie._t2x(serie.timeAccessor(this.lastNonNullSample))
      }

      //console.log('clientWidth: ', this.refs.svg.clientWidth, 'offsetX: ', offsetX)

      //  console.log('forcuje disable svg')
      this.onSvgMouseMove({
        target: { nodeName: 'svg' }, nativeEvent: {
          offsetX: offsetX
        }
      });
    }, 10);

    this.sizeDetector.lastSize.width = this.container.clientWidth;
    this.sizeDetector.lastSize.height = this.container.clientHeight;


    // console.log('sizedector: ', this.sizeDetector.lastSize)

    this.onResize({ size: this.sizeDetector.lastSize });

    this.setState(this.state);
    //  this.forceUpdate()
  }

  componentDidUpdate() {

    // this.checkComponentSize();
    var ref = ReactDOM.findDOMNode(this);

    this.legendItems = ref.getElementsByClassName('legend-item');
    this.legendContainer = ref.getElementsByClassName('legend-container');
    this.legendDateItem = ref.getElementsByClassName('legend-date')[0];

    //console.log(ref)
  }

  onResize(event) {

    if (this.refs.svg) {
      //this.refs.svg.style.width = event.size.width + "px";
      //his.refs.svg.style.height = event.size.height + "px";

      this.lastResizeEvent = event;

      //console.log('changing size of svg: ', this.refs.svg.style.width, this.refs.svg.style.height)

      // this.state.width = this.refs.svg.clientWidth;
      // this.state.height = this.refs.svg.clientHeight;

      if (this.props.reactiveDescription) {
        // we are trying to change chart parameters based on container size
        this.applyReactiveDescription(event.size);
      }

      this.forceUpdate();
    }
    //this.props.onResize(event.size);
  }

  applyReactiveDescription(size) {
    var d = this.props.reactiveDescription;

    for (var p in d) {
      for (var v in d[p]) {
        if (size[p] >= parseFloat(v)) {
          for (var r in d[p][v]) {

            if (r == "timeline") {
              this.state.timelineTicksCount = d[p][v][r].ticksCount;
            }

            if (r == "scale") {
              this.state.scaleTickSize = d[p][v][r].tickSize;
            }

            if (r == "legendSize") {
              this.state.legendSize = d[p][v][r];
            }

            //console.log('testing property: ', r)
            if (r == "containerHeight") {
              // console.log('setting new height to: ', this.container)

              //this.state.containerHeight = parseFloat(d[p][v][r]);
              this.container.style.height = (parseFloat(d[p][v][r]) * this.props.containerHeight) + "px";
            }
          }
        }
      }
    }
  }

  orientScales(scales, size) {
    var L = "left", R = "right";

    scales.forEach((r, i) => {
      switch (scales.length) {
        case 1: r.scale.orient = L; break;
        case 2: {
          switch (i) {
            case 0: r.scale.orient = L; break;
            case 1: r.scale.orient = R; break;
          }
          break;
        }
        case 3: {
          switch (i) {
            case 0: r.scale.orient = L; break;
            case 1: r.scale.orient = L; break;
            case 2: r.scale.orient = R; break;
          }
          break;
        }
        case 4: {
          switch (i) {
            case 0: r.scale.orient = L; break;
            case 1: r.scale.orient = L; break;
            case 2: r.scale.orient = R; break;
            case 3: r.scale.orient = R; break;
          }
          break;
        }
      }
    })
  }

  positionScales(scales, size) {
    var L = "left", R = "right";

    scales.forEach((r, i) => {
      switch (scales.length) {
        case 1: r.scale._x = size.x; break;
        case 2: {
          switch (i) {
            case 0: r.scale._x = size.x; break;
            case 1: r.scale._x = size.x + size.width; break;
          }
          break;
        }
        case 3: {
          switch (i) {
            case 0: r.scale._x = size.x - size.defaultScaleWidth; break;
            case 1: r.scale._x = size.x; break;
            case 2: r.scale._x = size.x + size.width; break;
          }
          break;
        }
        case 4: {
          switch (i) {
            case 0: r.scale._x = size.x - size.defaultScaleWidth; break;
            case 1: r.scale._x = size.x; break;
            case 2: r.scale._x = size.x + size.width; break;
            case 3: r.scale._x = size.x + size.width + size.defaultScaleWidth; break;
          }
          break;
        }
      }
    })
  }

  computePlaneSizeAndScales(size, scales) {
    var existingIndexes = {}

    // update scale indexes and sort scales
    scales.forEach((r, i) => {

      if (!isNaN(parseInt(r.scale.index))) {
        r.scale._index = r.scale._index;
        existingIndexes[r.scale.index] = r;
      }

      // just assign arbitrary index to the scale for now
      r.scale.index = i;
    })

    for (var i = 0; i < scales.length; i++) {

      if (!existingIndexes[i]) {
      }

      //      r.scale._index = i;
    }

    var sortedScales = scales.sort((a, b) => {
      var anan = isNaN(parseInt(a.scale.index));
      var bnan = isNaN(parseInt(b.scale.index));

      if (!anan) {

        // compare indexes
        if (!bnan) {
          return a.scale.index - b.scale.index;
        }

        // a greater
        return 1;
      } else if (!bnan) {
        // b greater
        return -1;
      } else {
        // equal
        return 0;
      }

    })

    var scaleWidthSum = 0, leftScalesSum = 0, rightScalesSum = 0;

    this.orientScales(scales, size);

    scales.forEach((r, i) => {
      var v = (r.scale.width ? r.scale.width : size.defaultScaleWidth);

      scaleWidthSum += v;

      leftScalesSum += (r.scale.orient == "left" ? v : 0);
      rightScalesSum += (r.scale.orient == "right" ? v : 0);
    })

    //  console.log('scaleWidthSum: ', size.width, scaleWidthSum, size.margin.left, size.margin.right, size.width - scaleWidthSum - size.margin.left - size.margin.right)

    //scalesCount = 0;
    Object.assign(size, {

      // compute size of the chart canvas without space for scales and margins
      width: size.width - scaleWidthSum - size.margin.left - size.margin.right,

      // x depends on sum of left scales width
      x: size.margin.left + leftScalesSum,

      // height depends on both vertical margins
      height: size.height - size.margin.top - size.margin.bottom,

      // y position depends on margin only
      y: size.margin.top
    })

    this.positionScales(scales, size)

    scales.forEach((r, i) => {


      // make first active
      // this depends on scale position (we are beginning drawing from the start position of given scale so we need to compensate for it)
      // if we decide to display many scales at once we need to be sure that ticks of active one doesnt cross others

      // we really should generate another scale
      r.scale._tickSize = ((scales.length != 2 && i == 1) || scales.length == 1 || (scales.length == 2 && i == 0)) ? -size.width : 0;

      //  console.log('my scale x :', r.scale._x, i, r.scale._tickSize)
    })

    ////console.log('output size object: ', size)t
  }

  createFauxChart(series) {
    ////console.log('generating faux chart with properties: ', this.props)

    var hMargin = 50, vMargin = 10;

    var size = {
      // here we should get properties from current object
      width: this.state.width,
      height: this.state.height,

      defaultScaleWidth: 50,

      margin: {
        left: 0,
        top: vMargin,
        right: 50,
        bottom: vMargin
      }
    }

    var svg = d3.select(ReactFauxDOM.createElement('svg')).attr({
      width: size.width, height: size.height, ref: "svg"
    }).style({
      //  border: '1px solid #eeeeee',
      width: '100%',
      height: 'calc(100% - 24px)',
      position: 'absolute',
      top: 24
    })

    // create some filters
    // filtry sa bardziej skomplikowane niz sie wydaje i nie dzialaj od reki
    // this.createFilters(svg);

    // return only those series that have scale visible on true
    var scales = series.filter((serie, i) => serie.scale.visible)
    this.computePlaneSizeAndScales(size, scales);

    // save last size
    this.lastSize = size;

    var max_extent = [null, null]

    if (this.props.shared_extent) {

      series.forEach((serie, i) => {
        var extent = serie._getScaleExtent();

        if (max_extent[0] == null || max_extent[0] > extent[0]) {
          max_extent[0] = extent[0];
        }


        if (max_extent[1] == null || max_extent[1] < extent[1]) {
          max_extent[1] = extent[1];
        }
      });
    }


    series.forEach((serie, i) => {


      if (this.props.shared_extent) {
        //console.log('using shared extent: ', max_extent)
        serie.scale.range = max_extent;

        if (this.props.hasOwnProperty('shared_extent_base')) {
          var ex_base = this.props.shared_extent_base;

          serie.scale.overrideRange = [ex_base < max_extent[0] ? ex_base : max_extent[0] == max_extent[1] ? 0 : max_extent[0], max_extent[0] == max_extent[1] ? this.props.shared_extent_ceil : max_extent[1]];

          //          console.log('my scale: ', serie.scale.range, max_extent, this.props.shared_extent_ceil)
        }
      }

      //svg.append('rect').attr(size).attr('fill', '#eeeeee');
      this.createD3Path(svg, serie, i, size);
    })

    //this.createLegend(svg, series, size);

    var node = svg.node();

    // console.log('node: ', node)
    // this is the safest way to assign react events
    node.props.onMouseMove = this.onSvgMouseMove.bind(this);

    return node.toReact();
  }

  startSizeDetector() {

    this.sizeDetector = {
      lastSize: { width: 0, height: 0 }, ts: 0
    }

    this.resizeSensor = new ResizeSensor(this.container, () => {
      this.sizeDetector.lastSize.width = this.container.clientWidth;
      this.sizeDetector.lastSize.height = this.container.clientHeight - 24;

      //console.log('rozmiary komponentu: ', this.tableContainer.offsetHeight, this.tableContainer.offsetWidth)
      this.onResize({
        target: this.container,
        size: this.sizeDetector.lastSize
      })
    })
  }

  render() {
    var props = this.props;

    if (this.refs.svg) {
      var bbox = this.refs.svg.getBoundingClientRect();

      // this.state.width = this.refs.svg.clientWidth;
      // this.state.height = this.refs.svg.clientHeight - 18;
      this.state.width = bbox.width;
      this.state.height = bbox.height - 24;
      // console.log('size: ', bbox)
    }

    //  console.log('rendering line chart')
    var faux = this.createFauxChart(props.series);

    if (this.props.selectionData) {
      //console.log('we are setting initial mouse event: ', this.lastResizeEvent, this.props.selectionData,"nativeOffset:", this.props.selectionData.event.nativeEvent.offsetX,  this.props.selectionData.series[0].q, "offsetX:", this.props.series[0]._i2x(this.props.selectionData.series[0].q))
      this.onSvgMouseMove({
        ...this.props.selectionData.event,
        // we also need to update the offsetX based on q value 
        nativeEvent: {
          // just compute again to make sure we are selecting the same sample 
          offsetX: this.props.series[0]._i2x(this.props.selectionData.series[0].q)
        }
      })
    } else {
      this.onSvgMouseMove(this.lastMouseEvent);
    }

    if (!this.props.onMouseMoveHandler && this.props.viewMode !== "history" ) {
      this.userActivityTimeoutId = setTimeout(() => {  //
        if (!this.refs.svg) return;

        var offsetX = this.refs.svg.clientWidth;
        if (this.lastNonNullSample) {
          var serie = this.props.series[0];

          offsetX = serie._t2x(serie.timeAccessor(this.lastNonNullSample))
        }

        //console.log('clientWidth: ', this.refs.svg.clientWidth, 'offsetX: ', offsetX)

        if (this.refs.svg.clientWidth)
          this.onSvgMouseMove({
            target: { nodeName: 'svg' }, nativeEvent: {
              offsetX: offsetX
            }
          })
      }, 10);
    }

    return <div ref={(r) => this.container = r} style={{ padding: 0, margin: 0, width: '100%', height: 'calc(100%)', background: '#fefefe' }}>
      {
        this.props.LegendComponent ? <this.props.LegendComponent {...this.props.legendProps} legendSize={this.state.legendSize} /> :
          <DefaultLegendComponent series={props.series} />
      }
      {faux}
    </div>;
  }
}

LineChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  chartId: PropTypes.string
};

LineChart.defaultProps = {
  width: 500,
  height: 200,
  chartId: 'v1_chart'
}

export default LineChart;
