
export function getFetchParams(type, codes, upd) {
  var vars = [], start = 0, end = 0;
  var now = new Date();

  // console.log('getting fetch params: ', type, codes, upd)

  vars = codes.map((r) => {
      var avg = "A";

      if (/WS|WD/.test(r)) {
        avg = "V";
      }

      if (/RAIN/.test(r) || /hBZ/.test(r)) {
        avg = "S";
      }

      if (/min/.test(r)) {
        avg = "I";
      }

      if (/max/.test(r)) {
        avg = "X";
      }


      return (type == "10s" ? r : r + ":" + avg + type);
    });
  //
  // vars = codes.map((r) => {
  //   return (type == "10s" ? r : r + ":A" + type);
  // });

  //console.log("my date is now: ", now)

  switch (type) {
    case "10s":
      end = Math.floor(now.getTime() / 1000);

      now.setHours(now.getHours() - 1);
      //console.log("my date is now 1h: ", now)

      start = Math.floor(now.getTime() / 1000);
    break;
    case "10m":
    case "1h":
      end = Math.floor(now.getTime() / 1000);

      now.setDate(now.getDate() - 1);
      now.setHours(now.getHours() - 1);
      //console.log("my date is now 1h: ", now)

      start = Math.floor(now.getTime() / 1000);
      break;
    case "24h":
      now.setHours(0);
      now.setDate(now.getDate() - 1);

      end = Math.floor(now.getTime() / 1000);

      now.setMonth(now.getMonth() - 1);
      //console.log("my date is now 24h: ", now)

      start = Math.floor(now.getTime() / 1000)
      break;
  }

  var result =  Object.assign({
    type: 'chart',
    avg: type,
    start: start,
    end: end,
    vars: vars.join(','),
    // processor: function (json) {
    //   return processResponse(json, type == "1h" ? 3600 : 86400)
    // }
  }, upd)

  //console.log('my new date range: ', new Date(result.start * 1000), new Date(result.end * 1000));
  return result;
}

export function processResponse(json, type, reduce = 1) {
  var startTimestamp = json.start;
  var endTimestamp = json.end;
  var tp = "ts";
  var ts = 0;
  var timeshift = 0;
  var j = 0;
  var valuesLength = json.values.length;

  var entryStep = type == "1h" ? 3600 : (type == "10s" ? 10 : 86400)

  if (type == "10m") {
    entryStep = 600;
  }

  //console.log('dupa: ', endDate.getTime())
  //    endTimestamp = Math.floor(((endDate.getTime() - 23 * 60 * 1000) / 1000));
  //
  if (entryStep == 86400) {
    // we can modify endTimestamp to match whole day
    var endDate = new Date(endTimestamp * 1000);
    //var endDate = new Date();
    endDate.setDate(endDate.getDate() + 1)
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);

    endTimestamp = Math.floor(endDate.getTime() / 1000);

    var startDate = new Date(startTimestamp * 1000);
    startDate.setHours(24);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    startTimestamp = Math.floor(startDate.getTime() / 1000);


  } else {
    startTimestamp = Math.ceil((startTimestamp) / entryStep) * entryStep;
    endTimestamp = Math.floor((endTimestamp) / entryStep) * entryStep;
  }

  // console.log('startTimestamp: ', startTimestamp, 'endTimestamp: ', endTimestamp, json.end, json.values.length, type )

  //  debugger;
  for (var j = 0; j < valuesLength; j++) {
    var data = json.values[j];

    // iterate over data and insert dummies
    var newArray = [];
    var len = data.length;
    var lastTs = startTimestamp;
    var k = 0;

    for (var i = 0; i < len; i++) {
      ts = data[i].t;

      if (i == 0 && ts > startTimestamp) {
        for (k = startTimestamp; k < ts; k += entryStep) {
          newArray.push({
            t: k, v: '', s: 'N', a: 'gap'
          });
        }

      } else if (ts - lastTs > entryStep) {

        for (k = lastTs + entryStep; k < ts; k += entryStep) {
          newArray.push({
            t: k, v: '', s: 'N', a: 'gap'
          });
        }
      }

      newArray.push(data[i]);
      lastTs = ts;
    }

    if (len == 0) {
      lastTs -= entryStep;
    }

    if (lastTs < endTimestamp) {

      // update it
      // this.endTimestamp = lastTs;
      for (var k = lastTs + entryStep; k <= endTimestamp; k += entryStep) {

        newArray.push({
          t: k, v: '', s: 'N', a: 'gap'
        });
      }
    }

    data = null;
    data = newArray;

    json.values[j] = data;
  }

  //console.log('przetworzeone: ', json.values);

    // now will convert into to normal table
    json.values = json.values[0].map((r, i) => {
      var sample = {t: r.t, __typename: 'Row'};
      json.vars.forEach((p, j) => {
      // console.log('trying to split: ', json.vars, p)
        if (reduce) {
          var code = p.split(':')[0].replace('.', '_');
          var s = json.values[j][i];

          // console.log('what is s: ', j, i, json.values.length, json.values[j].length, s)
          // we can test here for status
          sample["_" + code] = s.v;
        } else {
          var s = p.replace(/:/g, '_').replace('.', '_');
          sample["_" + s] = {...json.values[j][i], __typename: 'Sample'}
        }
      })
      return sample;
    })

  // console.log('result json: ', json)

  return json;
}
