import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks'

// we need to move creation of apollo client to separate file 
import { apolloClient } from 'app/graphql/MeteoPanel'


export const ALL_LOCATIONS_QUERY = gql`
    query ALL_LOCATIONS_QUERY($filter: String! = ".*") {
        location(filter: $filter) @client {
            id 
            name    
            measurement {
                id name code
            }                                        
        }
    }
`;

// this depends on cache, so the  query must be run before this code 
function execQueryQuery(query, variables) {
    return apolloClient.readQuery({
        query, variables
      });
};


export function getAllLocations(variables = {filter: ".*"}) {
    const result = execQueryQuery(ALL_LOCATIONS_QUERY, variables);
    return result;
}