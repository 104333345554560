
import api from '../data/FeyApi';
import Stores from 'app/graphql/meteopanel/context/MeteoPanelStores';

const resolvers = {
    Query: {
        location(parent, {filter}, context, info) {
            var devices = Stores['physical_device'].getData();
            var measurement = Stores['measurement_by_physical_device_id'];
            
            return devices.filter((dev) => new RegExp(filter).exec(dev.name)).map((dev) => {
                var measList = measurement.getRecordById(dev.id);

                if (!measList && dev.ds_map) {
                    measList = dev.ds_map.map((code) => {
                        return Stores['measurement_by_code'].getRecordById(code);
                    }).filter((meas) => meas)
                }

                console.log('resolving location query: ', dev, measList)

                return {
                    ...dev, 
                    measurement: measList.map((meas) => {return {...meas, __typename: 'measurement'}}),
                    __typename: 'location'
                }
            });
        },
    }
}

export default resolvers;