import { gql } from 'apollo-boost';


// function createTestQuery() {
//     // we should put constant queries in const variables 
//     return gql`
//     {
//         physical_device @client {
//             id name device_type_id station_id protocol address conn_args active
//             measurement @client {
//                 id code name physical_device_id agg_unit decimals compound_type unit_id 
//             }
//         }
//     }
//     `;
// }


function createTestQuery({dataUrl}) {
    // we should put constant queries in const variables 
    return gql`
    {
        measurement: tableData( url: "${dataUrl}", tableName: "measurement" ) @client {
            id code name physical_device_id agg_unit decimals compound_type unit_id
        }
    }
    `;
}
export default createTestQuery;