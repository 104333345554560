import {
    fetchData, fetchTable, fetchFile, validateFetchDataArguments, validateFetchTableArguments
} from './FeyApiImpl';

const FeyApi = {

    /** 
     * Fetches data from server for given time range and set of variables. 
     * 
     * @params url      Url of fey instance. 
     * @params uts1     Start of time range to fetch. 
     * @params uts2     End of time range to fetch. 
     * @params vars     Array of series specifications in format ${code}:${aggregation} .
     * @params dataType Data type to fetch, samples or averages (default: averages). 
     * @params opts     Additional options.
     * 
     * @return {Promise} Query to the server. 
     */

    fetchData: function (url, {uts1, uts2, vars, dataType = "averages", mem = 0}, opts) {
        
        // we need to be careful with default values 
        // defaults doesnt change arguments!

        var fetchArgs = {uts1, uts2, vars, dataType, mem};

        var vResult = validateFetchDataArguments(fetchArgs);

        if (!vResult.valid) {
            // eslint-disable-next-line no-throw-literal
            throw {
                message: "Fetch argument validation error! ", 
                name: "ValidationError", 
                result: vResult, 
                fetchArgs: fetchArgs
            };

        }

        return fetchData(url, fetchArgs);
    },
    
    /** 
     * Fetches table data.
     * 
     * @params url          Url of fey instance. 
     * @params tableName    Name of table data to fetch. 
     * @params opts         Additional options.
     * 
     */

    fetchTable: function (url, {tableName}, opts) {
        var vResult = validateFetchTableArguments(arguments[1]);

        if (!vResult.valid) {
            // eslint-disable-next-line no-throw-literal
            throw {
                message: "Fetch table validation error! ", 
                name: "ValidationError", 
                result: vResult, 
                fetchArgs: arguments[1] 
            };

        }

        return fetchTable(...arguments);
    },

   /** 
     * Fetches file.
     * 
     * @params url          Url of fey instance. 
     * @params fileName    Name of table data to fetch. 
     * @params opts         Additional options.
     * 
     */

    fetchFile: function (url, {fileName}, opts) {
        // TODO: add validation 
        var vResult = {valid: true};

        if (!vResult.valid) {
            // eslint-disable-next-line no-throw-literal
            throw {
                message: "Fetch file validation error! ", 
                name: "ValidationError", 
                result: vResult, 
                fetchArgs: arguments[1] 
            };

        }

        return fetchFile(...arguments);
    },



    /** 
     * Validates fetch data arguments.
     * All proper 
     * 
     * @params uts1     Start of time range to fetch. 
     * @params uts2     End of time range to fetch. 
     * @params vars     Array of series specifications in format ${code}:${aggregation} 
     * @params opts     Additional options.
     * 
     * @return {Promise} Query to the server. 
     */
  
    validateFetchDataArguments: function ({uts1, uts2, vars, dataType}) {
        return validateFetchDataArguments(...arguments);
    },
    
    /** 
     * Validates fetch table arguments.
     * All proper 
     * 
     * @params tableName     Name of a table to fetch. 
     * 
     * @return {Object}     Validation result.
     */
  
    validateFetchTableArgument: function ({uts1, uts2, vars, dataType}) {
        return validateFetchTableArguments(...arguments);
    },
    

}


export default FeyApi;


