
import { Icon as LeafletIcon } from 'leaflet';


const colors = [
  "#2D99CD",// "rgb(0,255,255)",
  "#053466",//  "rgb(201,218,248)",
  "#6EA837", //"rgb(0,255,0)",
  "#EDC410", //"rgb(255,255,0)",
  "#EA9412", //"rgb(255,153,0)",
  "#CB0B1C", //"rgb(255,0,0)"
];

        
const IconSize = [32, 32];

const IndexIcons = [
  new LeafletIcon({
    iconUrl: require('../../icons/gdynia/01.svg').default,
    iconSize:    IconSize
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/gdynia/02.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/gdynia/03.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/gdynia/04.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/gdynia/05.svg').default,
    iconSize:    IconSize,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/gdynia/06.svg').default,
    iconSize:    IconSize,
  })
]

var UndefinedIcon =   new LeafletIcon({
    iconUrl: require('../../icons/gdynia/07.svg').default,
    iconSize:    IconSize,
});

const IconSize2 = [48, 48];


const AltIndexIcons = [
  new LeafletIcon({
    iconUrl: require('../../icons/armaag/01.svg').default,
    iconSize:    IconSize2
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/armaag/02.svg').default,
    iconSize:    IconSize2,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/armaag/03.svg').default,
    iconSize:    IconSize2,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/armaag/04.svg').default,
    iconSize:    IconSize2,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/armaag/05.svg').default,
    iconSize:    IconSize2,
  }),
  new LeafletIcon({
    iconUrl: require('../../icons/armaag/06.svg').default,
    iconSize:    IconSize2,
  })
]

var AltUndefinedIcon =   new LeafletIcon({
    iconUrl: require('../../icons/armaag/07.svg').default,
    iconSize:    IconSize2,
});


const SITE_TYPE_ARMAAG = 'ARMAAG';
const SITE_TYPE_GDYNIA = 'GDYNIA';

function getIndexIcon(loc, infoData) {
  var type = /AM\d+/.test(loc.site) ? SITE_TYPE_ARMAAG : SITE_TYPE_GDYNIA;

  var icons = type == SITE_TYPE_GDYNIA ? IndexIcons : AltIndexIcons;
  var uicon = type == SITE_TYPE_GDYNIA ? UndefinedIcon : AltUndefinedIcon;

  console.log('getting index icon: ', icons, infoData && typeof infoData.qualityIndex != "undefined" ? icons[infoData.qualityIndex] : uicon)

  return infoData && typeof infoData.qualityIndex != "undefined" ? icons[infoData.qualityIndex] : uicon;
}


function getIndexColor(level) {
  return (typeof level !== "undefined" ? colors[level] : "grey");
}  



export {
  getIndexIcon, IndexIcons, AltIndexIcons, UndefinedIcon, AltUndefinedIcon, getIndexColor
}
