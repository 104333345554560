
import api from '../data/FeyApi';


function reduceObject(obj) {
    var out = {
        __typename: 'metajson'
    };

    for (var field in obj) {
        if (field.indexOf('.') > -1 || field == "Zarzadca") {
            var [main, property] = field.split('.');


            if (!out.hasOwnProperty(main) || typeof out[main] !== "object") {
                out[main] = {};
            }

            if (property && obj[field]) {
                out[main][property] = obj[field];
            }
        } else {
            out[field] = obj[field];
        }
    }

    return out;
}



const resolvers = {
    Query: {
        tableData(parent, { url, tableName }, context, info) {
            //console.log('resolving table query: ', url,  tableName);

            return new Promise((resolve, reject) => {
                api.fetchTable(url, {
                    tableName: tableName
                })
                    .then((response) => {
                        // console.log('response data: ', response)
                        return resolve(response.data.map((r) => { return { ...r, __typename: tableName } }));
                    })
                    .catch((response) => reject(response));
            })
        },

        metaData(parent, { url, gid, fileName = "meta.json" }, context, info) {
            var gidRegExp = new RegExp(gid);

            console.log('resolving meta query: ', url, gid, fileName);

            return new Promise((resolve, reject) => {
                api.fetchFile(url, {
                    fileName: fileName
                })
                    .then((response) => {

                        return resolve(response.filter((r) => {
                            console.log('testing metajson: ', r.GID, r, r.active_p, gidRegExp.test(r.GID))
                            return r.active_p == 'tak' && gidRegExp.test(r.GID)
                        }).map((r) => {
                            return reduceObject(r);
                        }));
                    })
                    .catch((response) => reject(response));
            })
        },

        jsonData(parent, { url, fileName = "collections.json" }, context, info) {
            console.log('resolving jsonquery: ', url, fileName);

            return new Promise((resolve, reject) => {
                api.fetchFile(url, {
                    fileName: fileName
                }).then((response) => {
                    console.log('jsonquery response: ', response)
                    return resolve(response.map((r) => {
                        return {...r, __typename: fileName.split('.')[0]}
                    }))
                }).catch((response) => { 
                    console.log('jsonquery rejected', response)
                    return reject(response)
                });
            })
        },


        // measurement(parent, { url }, context, info) {

        //     console.log('resolving table query measurement: ', url);

        //     return new Promise((resolve, reject) => {
        //         api.fetchTable(url, {
        //             tableName: 'measurement'
        //         })
        //         .then((response) => {
        //             console.log('response data: ', response)
        //             return resolve(response.data.map((r) => {return {...r, __typename: tableName}}));
        //         })
        //         .catch((response) => reject(response));
        //     })
        // }




    }
}

export default resolvers;