
import { gql } from 'apollo-boost';


function createChartDataQuery({ dataUrl, dataType = "averages", uts1, uts2, vars, columns, step, mem = 0 }) {

    var mvars = vars.map((v) => v.replaceAll('.', '_'));
    var mcols = columns.map((v) => v.replaceAll('.', '_'));

    
    return gql`
    {
        chartData(url: "${dataUrl}", uts1: ${uts1}, uts2: ${uts2}, vars: [${mvars}], columns: [${mcols}], step: "${step}", dataType: "${dataType}", mem: ${mem}) @client {
            t ${mcols.join(" ")}
        } 
    }
    `;
}

export default createChartDataQuery;