const _beginEraDate = new Date('2000-01-01 01:00:00');

function validCode(code) {
    return /[0-9A-Za-z]{1,10}/.test(code);
}

function validAggregation(agg) {
    return /([ASXIV])(\d{1,2})([smhM])/.test(agg);
}

function validSerieSpec(spec) {
    var parts = spec.split(":");

    return parts.length === 2 ? validCode(parts[0]) && validAggregation(parts[1]) : validCode(spec);
}

function validTimestamp(uts) {
    var ts = uts * 1000;
    var now = new Date(); 
    now.setFullYear(now.getFullYear() + 1);

    return (ts > _beginEraDate.getTime() && ts < now.getTime());
}

function validDataType(dataType) {
    return ["samples", "averages", "org_averages"].indexOf(dataType) > -1;
}

function validateFetchDataArguments({uts1, uts2, vars, dataType}) {
    var res = {
        // valid range 
        range: {
            uts1: { value: uts1, dateValue: new Date(uts1 * 1000), valid: validTimestamp(uts1) },
            uts2: { value: uts2, dateVlaue: new Date(uts2 * 1000), valid: validTimestamp(uts2) }
        },
        // valid variables
        vars: vars.map((spec) => {
            return {
                value: spec, 
                valid: validSerieSpec(spec)
            }
        }),
        // valid dataType
        dataType: {
            value: dataType,
            valid: validDataType(dataType)
        }
    }

    return {
        ...res,
        valid: res.range.uts1.valid && res.range.uts2.valid && !res.vars.some((v) => !v.valid) && res.dataType.valid
    }
}

function validTableName(tableName) {
    return ["measurement", "chemical_compound", "station", "device_type", "physical_device"].indexOf(tableName) > -1;
}


function validateFetchTableArguments({tableName}) {
    return {
        tableName: tableName,
        valid: validTableName(tableName)
    }
}

export {
    validateFetchDataArguments, validSerieSpec, validTimestamp, validTableName, validateFetchTableArguments
}