import { gql } from 'apollo-boost';


function createInitialDataQuery({dataUrl, gid, metaUrl, metajson}) {

    // we should put constant queries in const variables 
    return gql`
    {
        measurement: tableData( url: "${dataUrl}", tableName: "measurement" ) @client {
            id code name physical_device_id agg_unit decimals compound_type unit_id
        }
        
        chemical_compound: tableData( url: "${dataUrl}", tableName: "chemical_compound" ) @client {
            id code code_html long_name long_html short_name ranges mol_const decimals format scale_min scale_max ord
        }

        physical_device: tableData( url: "${dataUrl}", tableName: "physical_device" ) @client {
            id name device_type_id station_id protocol address conn_args active
        }

        metajson : metaData( url: "${metaUrl}", gid: "${gid}", fileName: "${metajson}") @client {
            ROW_NO
            Owner
            GID
            G2
            site
            active_p
            status
            serialNo
            uwagi
            imgSrc
            imgHeight
            oznaczenie
            Opis
            comment
            zindex
            channels
            groups
            lat
            lon
            alt
            diff
            gps
            area
            name
            address
            ulica
            location
            what0
            what1
            what2
            what3
            what4
            what5
            what6
            dataBeg
            dataEnd
            measurementList
            dataUrl
            sensorPM
            ds
            meteo
            cal
            cend
            Gdzie
            Montaz
            Zarzadca
            END
        }

        collections: jsonData( url: "${metaUrl}", fileName: "collections.json") @client {
            name   
            codes 
        }
    }
    `;
}

export default createInitialDataQuery;