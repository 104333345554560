
import React, {Component} from 'react';


const headerStyle = {
    textAlign: 'center', padding: 5, fontSize: '18px', fontWeight: 'bold',
    border: '1px solid #eee', 
    borderRadius: 10
}


// add stylesheet for mouse over

const HorizontalTabs = ({tabs, onItemClick, selectedTab, icon, buttons, selectedButton, buttonsHandler}) => {
    // console.log('horizontal icon: ', icon)

    return (
        <table key={"t"} cellSpacing={1} style={{width: '100%', height: "32px", cursor: 'pointer'}}>
            <tbody key={"b"}>
                <tr>
                {
                    tabs.map((t, i) => <td key={i} onClick={(event) => onItemClick(t, i)} style={Object.assign({}, headerStyle, {
                        background: t.name === selectedTab ? "#eee" : ""
                    })}><div style={{lineHeight: "32px", height: 32, float: tabs.length > 1 ? '' : 'left'}}>{t.name}</div>{
                        [
                            // conditional rendering for buttons and icon  
                            buttons ? <div key={"yay"}>
                                {buttons.labels.map((txt)=> {
                                    return <button key={txt} className={txt == selectedButton ? "charts-button selected" : "charts-button"} onClick={(ev) => buttonsHandler(txt)}>{txt}</button>
                                })}
                            </div> : null,

                            tabs.length == 1 && icon ? <img key={"nay"} style={{width: 32, height: 32, float: 'right'}} src={icon && icon.options.iconUrl}/> : null

                        ]
                        }
                    </td>)
                }
                </tr>
            </tbody>
        </table>
    )
}


export default HorizontalTabs;