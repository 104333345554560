

// import legacy datastore
import DataStore from '../../../legacy/react/data/DataStore';

const Stores = {
    // just measurements 
    'measurement': new DataStore({
        model: { id: 'id' }
    }),

    // measurement by codes 
    'measurement_by_code': new DataStore({ 
        model: { id: 'code' } 
    }),

    // chemical compounds 
    'chemical_compound': new DataStore({ 
        model: { id: 'compound_type' } 
    }),

    // measurements by chemical_compound
    'measurement_by_compound': new DataStore({ 
        model: { id: 'compound_type' } 
    }),

    // measurement by physical_device 
    'measurement_by_physical_device_id': new DataStore({ 
        model: { id: 'physical_device_id' } 
    }),

    // just physical_devices 
    'physical_device': new DataStore({ 
        model: { id: 'id' } 
    }),

     // just physical_devices 
     'site': new DataStore({ 
        model: { id: 'code' } 
    }),
};

export default Stores;